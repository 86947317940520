// This is used by dusk tests to avoid delay
// from animations like when modal shows-up.

html.limit-transitions {
  .modal.fade {
    opacity: 1;
  }

  .modal.fade .modal-dialog,
  .modal.in .modal-dialog {
    transform: translate(0, 0);
  }
}
