.acc-registration_row {
  max-width: 1400px;
  margin: 0 auto;
}

.acc-registration_col {
  padding: 4rem 2em;
}

@include media-breakpoint-up(md) {
  .acc-registration_form {
    width: 650px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@include media-breakpoint-up(lg) {
  .acc-registration_col {
    padding: 4em;
  }
}

.acc-fullscreen-register-text-container {
  max-width: 35rem;
  margin-top: 2.5rem;
  color: $gray-800;

  .acc-fullscreen-register-text-container__content-box {
    margin-bottom: 3.5rem;
  }

  ul {
    padding-left: 1.25rem;

    li {
      margin-bottom: 0.25rem;
    }
  }

  h3 {
    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: 0.1em;
    color: $gray-800;
  }

  .testimonial_manager {
    font-size: 0.75rem;
    font-weight: bold;
    color: $gray-800;
  }
}

.acc-registration-page {
  background: $gray-200;

  .top-navbar {
    margin-bottom: 3.25rem;
  }
}
