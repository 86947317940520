.acc-desktop-min-body {
  min-width: map-get($grid-breakpoints, md);
}

.nav-crumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  .nav-crumb-item {
    color: $gray;

    a {
      color: $gray;
    }

    &.active {
      color: $navy;
    }
  }
}

.navbar-spacer {
  opacity: 0;
  width: 34px;
}

.acc-topbar-brand-link-img {
  height: 28px;
  padding-bottom: 5px;
}

.modal .modal-content {
  border-radius: 10px;
}

.container-responsive {
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.navbar .container-responsive {
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 100%;
  }
}

.content {
  text-align: center;
}

.title {
  font-size: 84px;
}

.acc-page-title {
  font-family: $font-family-default;
  padding-top: 30px;
}

.acc-page-subtitle {
  font-family: $font-family-default;
  color: $primary;
  font-weight: bold;
}

.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.acc-danger-link {
  color: $danger;
  font-size: 0.8em;
  text-decoration: underline;
  cursor: pointer;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.thank-you-modal {
  .modal-footer {
    @extend .justify-content-center;

    text-align: center;
  }

  .modal-body {
    padding: 15px 100px;
  }
}

.acc-message-box-wide {
  background: white;
  border-radius: 5px;
  padding: 20px 0;
}

.acc-message-box {
  @extend .acc-message-box-wide;

  padding: 20px;
}

.acc-important {
  color: $red;
}

.acc-icon-to-left-of-text {
  margin-right: 10px;
}

.acc-icon-to-left-of-text-smaller {
  margin-right: 0.3em;
}

.acc-icon-sup {
  position: relative;
  bottom: 0.5em;
}

.acc-display-when-drawer-expanded:not([aria-expanded='true']) {
  display: none;
}

.acc-display-when-drawer-collapsed[aria-expanded='true'] {
  display: none;
}

.acc-ml-half {
  margin-left: 0.5em;
}

.acc-ml-1 {
  margin-left: 1em;
}

.acc-ml-2 {
  margin-left: 2em;
}

.acc-mr-half {
  margin-right: 0.5em;
}

.acc-mr-1 {
  margin-right: 1em;
}

.acc-mr-2 {
  margin-right: 2em;
}

.acc-mr-6 {
  margin-right: 6em;
}

.acc-mt-0 {
  margin-top: 0 !important;
}

.acc-mt-half {
  margin-top: 0.5em;
}

.acc-mt-1 {
  margin-top: 1em;
}

.acc-mt-2 {
  margin-top: 2em;
}

.acc-mb-0 {
  margin-bottom: 0 !important;
}

.acc-mb-1 {
  margin-bottom: 1em !important;
}

.acc-mb-2 {
  margin-bottom: 2em;
}

.acc-mb-4 {
  margin-bottom: 4em;
}

.acc-tiny-label {
  font-size: 0.8em;
  color: $gray-800;
}

.acc-label-xs {
  font-size: 0.7rem;
  color: $gray-800;
}

.acc-btn-block-sm {
  display: inline-block;

  .btn-sm {
    padding: 4px 5px;
    min-width: 60px;
  }
}

.acc-btn-resonate {
  font-size: 14px;
}

.acc-radio-row {
  margin-bottom: 10px;
}

.acc-normal-size-label {
  font-size: 1em;
}

.acc-big-label {
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 1em;
}

.acc-xl-label {
  @extend .acc-big-label;

  font-size: 2em;
  color: $gray-800;
}

.acc-xl-link {
  font-size: 2em;
}

.acc-icon-btn {
  color: $primary;
  border: none;
  font-size: 20px;
  font-weight: bold;
  padding: 2px 2px 2px 6px;
  background-color: transparent;

  // bg semi opaque in middle, fade out to edge
  background-image:
    radial-gradient(
      closest-side,
      rgb(255 255 255 / 50%) 75%,
      rgb(255 255 255 / 0%) 100%
    );
}

.acc-inline-block {
  display: inline-block;
}

.acc-select {
  padding: 10px 20px;
}

.acc-inline-select {
  padding: 5px 0 5px 10px;
  margin-right: 0.5em;
}

.btn-secondary.text-danger {
  color: $danger;
  border-color: $danger;
}

.acc-team_card {
  white-space: normal;
  padding-top: 40px;
  width: 200px;
  height: 200px;
  background: white;
  box-shadow: 0 0 1em lightgrey;
  margin: 5px;
  color: #373a3c;
  font-weight: bold;

  &:hover {
    box-shadow: 0 0 1em grey;
  }
}

.tool_card {
  background: white;
  box-shadow: 0 0 1em lightgrey;
  padding-bottom: 15px;
  padding-top: 15px;
  min-height: 280px;

  &.acc-equal-padding {
    padding: 15px;
  }
}

.acc-giant-number {
  display: block;
  height: 120px;
  font-size: 90px;
  margin: 10px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  color: $primary;
}

.acc-number-xl {
  display: block;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  color: $primary;
}

.acc-btn-xl {
  font-size: 30px;
}

.acc-inline-check {
  margin-top: 5px;
  margin-bottom: 5px;
}

.acc-inline-check-text {
  margin-left: 5px;
  margin-right: 10px;
}

.acc-calendar-input {
  padding: 3px;
}

.acc-text-input-icon {
  position: relative;
  left: -20px;
}

.acc-div-btn {
  display: block;
  width: 100%;
  background: none;
  padding: 0;
  border: none;
  border: 1px dashed transparent;

  &:hover,
  &:focus {
    border: 1px dashed gray-700;
  }
}

.acc-colorfulText-1 {
  color: $primary;
  font-weight: bold;
}

.acc-colorfulText-2 {
  color: #ff9900; // Parker wanted to try this color in Perspectives report text to see if it makes it 'pop'
  font-weight: bold;
}

.acc-dark-background {
  background-color: $navy;

  a {
    color: $navy-fg3;

    &:hover,
    &:focus {
      color: $white;
      background-color: $navy-active;
    }
  }
}

.btn-outline {
  color: $primary;
  border: 0.7px solid $primary;
  background: $white;

  &:hover,
  &:focus {
    color: $primary-darker;
    border-color: $primary-darker;

    &:active {
      border-color: darken($primary-darker, 30%);
      color: $primary;
      background: $gray-200;
    }
  }

  &.acc-disabled {
    &:not([data-toggle='tooltip']) {
      pointer-events: none;
    }

    cursor: not-allowed;
    color: $gray-600;
    border-color: $gray-800;
    background: $gray-200;
  }
}

.acc-collapse-link {
  font-weight: bold;
}

.acc-clamp-height-demo-content {
  max-height: 75vh;
  overflow-y: scroll;
}

.acc-border-top-primary {
  border-top: 1px solid $primary;
}

.acc-border-bottom-primary {
  border-bottom: 1px solid $primary;
}

@include media-breakpoint-up(sm) {
  .acc-small-dl {
    dt {
      width: 50%;
      margin-right: 1em;
    }

    dd {
      text-align: left;
    }
  }
}

.acc-vertical-stretch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.markdown-editor {
  background: white;
  background-clip: padding-box;
  border: 2px solid $gray-400;
  padding: 5px 0;

  .ProseMirror {
    padding: 1rem;
    line-height: 1.4;
    outline: none;
    overflow: auto;

    p {
      margin-bottom: 1em;
    }
  }
}

.border-bottom-dotted {
  border-bottom: 1px dotted $gray-400;
}

.btn-unstyled {
  @include button-variant(transparent, transparent);

  color: lighten($body-color, 20%);

  @include hover {
    color: $body-color;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba(lighten($body-color, 50%), 0.5);
  }

  &:disabled,
  &.disabled {
    color: lighten($body-color, 15%);
    pointer-events: none;
  }
}

.acc-link-unstyled-text,
.acc-link-unstyled-text:hover {
  text-decoration: none;
  color: inherit;
}

.acc-color-box-xs {
  display: inline-block;
  width: 2em;
  height: 0.8em;
}

.acc-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.quick-disappear {
  animation: disappear 0.2s ease;
}

.acc-fadein {
  animation: fadein 0.5s ease;
}

.acc-fadein-quick {
  animation: fadein 0.2s;
}

.acc-fadeout-quick {
  animation: fadein 0.2s reverse;
}

.acc-fadeinout {
  animation: fadein 0.5s ease, fadein 0.2s reverse 2s forwards;
}

.acc-selectable-list_item {
  padding: 0.45rem 0.75rem;
  cursor: pointer;
  background: transparent;

  &:hover,
  &:focus {
    background: $gray-200;
  }

  &:active {
    background: $gray-200;
  }

  &.acc-selected {
    background: $gray-300;

    &:hover,
    &:focus {
      background: $gray-300;
    }

    &:active {
      background: $primary-lighter;
    }
  }
}

.acc-gray-text {
  color: $gray-800 !important;
}

.acc-semibold {
  font-weight: 600;
}

.acc-multiselect-search {
  position: relative;

  &-caret {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 3em;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &-menu {
    display: none;
    position: absolute;
    width: 100%;
    overflow-y: auto;
    padding: 10px 0;
    background: $white;
    box-shadow: 0 1px 4px rgb(0 0 0 / 13%);
    border-radius: 0 0 4px 4px;
    z-index: $zindex-dropdown;

    &.active {
      display: block;
    }
  }

  &-item,
  &-heading,
  &-subheading,
  &-select-all {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
      background: $gray-200;
      cursor: pointer;
    }
  }

  &-checkmark {
    font-size: 14px;
  }
}

.acc-multiselect-search-heading {
  font-size: $h5-font-size;
}

.acc-multiselect-search-subheading {
  font-size: $h6-font-size;
}

.acc-multiselect-search-heading,
.acc-multiselect-search-subheading {
  color: $headings-color;
  font-weight: $font-weight-semi-bold;
}

.acc-multiselect-search-heading,
.acc-multiselect-search-select-all,
.acc-multiselect-search-subheading,
.acc-multiselect-search-item {
  padding: 7px 15px;
  margin: 0;
}

.white-box {
  padding: 25px;
  background-color: white;
}

.white-box h3 {
  margin: 0;
}

.row-separated {
  padding-top: 4px;
}

.acc-toggle {
  $size: 1.4rem;
  $bg-color: $gray-400;
  $bg-color-active: $primary;

  display: inline-block;
  position: relative;
  width: 2 * $size;
  height: $size;
  vertical-align: bottom;
  cursor: pointer;
  transform: translate3d(0, 0, 0);

  input[type='checkbox'] {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    z-index: 1;
    font-size: 100%;
    border: none;
  }

  .acc-toggle-state {
    width: 100%;
    height: 100%;
    background: $bg-color;
    border-radius: 0.75 * $size;
    transition: all 0.2s ease;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: #ffffff;
      top: 50%;
      left: 0;
      width: 0.76 * $size;
      height: 0.76 * $size;
      border-radius: 50%;
      box-shadow: 0 0 3px rgb(0 0 0 / 10%);
      transition: all 0.2s ease;
      transform: translate3d(0.12 * $size, -50%, 0);
    }
  }

  & > :checked ~ .acc-toggle-state {
    background: $bg-color-active;

    &::after {
      transform: translate3d(1.12 * $size, -50%, 0);
    }
  }
}

.acc-toggle-sized-padding {
  padding-left: 2.8rem;
}

.acc-btn-icon-button-jumbo {
  @extend .btn-outline-primary;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  white-space: initial;
  padding: 10px 20px;
  margin: 15px 10px;
  width: 204px;
  height: 140px;
  border-radius: 4px;

  &:not(:hover) {
    .acc-icon-text {
      color: $navy;
    }
  }
}

.acc-btn-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.clickable {
  cursor: pointer !important;
}

.dropdown-menu-scrollable {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

@include media-breakpoint-up(lg) {
  .border-lg-left {
    border-left: $border-width solid $border-color !important;
  }
}

// Override default bootstrap tabs
.nav-tabs.simple-nav-tabs {
  .nav-link,
  .nav-link:hover {
    color: $gray;
    border: none;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-right: 2rem;
  }

  .nav-link.active {
    background: transparent;
    border-style: none none solid;
    border-bottom-width: 2px;
    border-bottom-color: $primary;
    color: $primary;
  }
}

.card--active {
  border: 1px solid $primary;
}

.main-content {
  padding-top: 20px;
}

@include media-breakpoint-up(lg) {
  .main-content {
    padding: 30px;
  }
}

.badge-circle {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  line-height: 1.25rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: text-bottom;
}

.input-group-seamless {
  display: inline-block;
  position: relative;

  & > i {
    position: absolute;
    left: 0.75rem;
    top: 0.45rem;
    color: #adb5bd;
  }

  & > input {
    padding-left: 2rem;
  }

  &.input-group-seamless-sm {
    & > i {
      top: 0.25rem;
    }
  }
}

.selectable-list {
  margin: 0 -1rem;
}

.selectable-list-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  background-color: transparent;
  color: $text-color;

  &:hover,
  &:focus {
    color: $text-color;
    background-color: $gray-200;
  }

  &.active {
    $alert-info-bg: #d7f1f6;

    background-color: $alert-info-bg;
  }

  &:active {
    color: $white;
    background-color: $primary;
  }
}

.modern-alert {
  display: flex;

  & > i {
    font-size: 1.25rem;
  }

  .modern-alert-content {
    flex: 1;
    margin-left: 1rem;
    padding-top: 0.1rem;

    h5 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      padding: 0;
    }
  }

  &.success i,
  &.success .modern-alert-content h5 {
    color: #20cbad;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.big-option-button {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid $gray-400;
  padding: 1rem 0.75rem;
  border-radius: 5px;
  color: $gray;
  text-align: center;
  line-height: normal;
  cursor: pointer;

  &:hover {
    color: $gray;
  }

  &.active {
    font-weight: $font-weight-semi-bold;
    border-color: $primary;
    color: $primary;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    right: 12px;
  }
}

.progress-indicator {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;

  .progress-step {
    display: block;
    flex: 1;
    position: relative;
    text-align: center;
    color: $gray;

    &::before {
      content: '';
      display: block;
      background: linear-gradient(90deg, $gray-400 50%, $primary 50%);
      background-position: 0%;
      background-size: 200%;
      height: 3px;
      margin-left: 50%;
      width: 100%;
      margin-bottom: 14px;
      transition: background-position 0.3s ease;
    }

    &::after {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -5px);
      content: '';
      background: #ffffff;
      border: 2px $gray-500 solid;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      transition: border-width 0.2s ease 0.2s;
    }

    &:last-child {
      &::before {
        background: transparent;
      }
    }
  }

  .progress-step.done {
    color: $gray-400;

    &::before {
      background: linear-gradient(90deg, $gray-400 50%, $primary 50%);
      background-size: 200%;
      background-position: -100%;
    }

    &::after {
      content: '';
      border: 7px $primary solid;
    }
  }

  .progress-step.active {
    color: $primary;
    font-weight: $font-weight-semi-bold;

    &::after {
      content: '';
      border: 7px $primary solid;
    }
  }
}

.logical_container {
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;
}

.start-guide-tools {
  a.card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 1.125rem;
    border: 1px solid $gray-300;
    border-radius: 5px;
    color: $gray;
    text-align: center;
    line-height: normal;

    span {
      min-height: 2.5rem;
    }
  }
}

.alert.alert-new-feature,
.alert-new-feature {
  display: flex;
  position: relative;
  padding: 1rem;
  background: $aliceblue;
  color: #6c85ab;
  border: 0;
  line-height: 1.3;
  font-size: 1rem;

  .close {
    position: absolute;
    color: $text-muted;
    top: 0;
    right: 14px;
    font-size: 1.75rem;
  }

  i {
    display: inline-block;
    font-size: 1.25rem;
    margin-right: 0.5rem;

    svg {
      vertical-align: middle;
    }
  }
}

.ordered-list-orb {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.625rem;
  font-size: 0.875rem;
  border-radius: 50%;
  background: $primary;
  text-align: center;
  font-weight: $font-weight-bold;
  color: $white;
  position: relative;
  top: -2px;
}

.text-black {
  color: $black !important;
}

// Semi Transparent Overlay
.dimmed-overlay {
  position: relative;
}

.dimmed-overlay::after {
  content: ' ';
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 / 10%);
  backdrop-filter: blur(2px);
  border-radius: 0.5rem;
}
