.acc-modal {
  color: $gray-800;

  // font-family: "Museo Sans";
  font-size: 14px;
  line-height: 22px;
  width: 800px;

  h1 {
    color: $navy;

    // font-family: "Neuzeit Grotesk";
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
  }

  h2 {
    color: $gray-800;

    // font-family: "Neuzeit Grotesk";
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }

  h3 {
    color: $gray-800;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
  }

  @mixin modalh4 {
    color: $navy;

    // font-family: "Museo Sans";
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }

  h4 {
    @include modalh4;
  }

  .modal-dialog {
    width: 800px;
  }

  .modal-content {
    max-height: 620px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    padding: 15px 20px;
    height: 58px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);

    & .close {
      line-height: 36px; // Easy vertically center the 'close' button
    }
  }

  .modal-body {
    flex: 1;
    padding: 0;
  }

  .acc-modal-tabbed {
    display: flex;

    & > .nav {
      padding: 0;
      width: 200px;
      border-right: 1px solid $gray-300;
    }

    & > .tab-content {
      flex-grow: 1;
    }

    & + .modal-footer {
      border-top: 1px solid $gray-300;
    }
  }

  .nav-pills > li {
    & > a {
      @include modalh4;

      height: 58px;
      margin: 0;
      padding: 0 20px;
      width: 200px;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.active > a {
      color: $white;
    }

    border-bottom: 1px solid $gray-300;
  }

  .modal-body {
    height: 397px;
  }

  .acc-modal-tab {
    padding: 20px 15px;
  }
}

%acc-plain-modal {
  background: rgb(21 24 27 / 51%);

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #262626;
    font-weight: 300;
    text-shadow: none;
    font-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 1;
    z-index: 1;
  }

  // style override
  h1,
  h2,
  h3,
  h4 {
    color: #4a4a4a;
  }

  .modal-content {
    color: #4a4a4a;
    top: 10vh;
    border: none;
    border-radius: 5px;
    background: white;
    box-shadow: 0 2px 11px 2px rgb(0 0 0 / 26%);
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .modal-body {
    padding: 30px 20px 30px 30px;

    p:last-of-type {
      margin-bottom: 30px;
    }
  }

  .modal-illustration {
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .modal-center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-vertical-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      margin: 7px;
    }
  }

  .modal-horizontal-buttons {
    text-align: center;

    .btn {
      margin: 7px;
    }
  }

  .btn {
    min-width: 142px;
  }
}

.acc-welcome-modal {
  @extend %acc-plain-modal;

  .modal-content {
    background: white;
    display: flex;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .modal-body {
    padding: 30px 20px 30px 30px;

    p:last-of-type {
      margin-bottom: 30px;
    }
  }

  .modal-illustration {
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    padding: 20px;
  }
}

.modal.acc-welcome-modal.what-is-it-modal {
  .modal-dialog {
    max-width: 573px;
  }

  .modal-content {
    top: 0;
  }

  .carousel-inner .acc-card-footer {
    margin-top: 20px;
  }

  .carousel-indicators {
    bottom: 50px;
  }
}

.modal.acc-exercise-types-modal {
  // wide enough to fit 3 cards
  .modal-dialog {
    max-width: 770px;
  }

  .modal-content {
    width: 770px;
  }

  .modal-body {
    padding: 2rem 1rem;
  }

  button.close {
    position: absolute;
    right: 15px;
    top: 10px;
    float: none;
    font-size: 24px;
    font-weight: normal;
  }
}

.acc-illustration-top {
  flex-flow: column;

  .acc-top-background {
    position: absolute;
    height: 195px;
    left: 0;
    right: 0;
    border-radius: 5px 5px 0 0;
  }

  .modal-illustration-top {
    height: 195px;
    border-radius: 5px 5px 0 0;
    justify-content: center;
  }

  .carousel-indicators {
    top: 170px;
    bottom: initial;
  }

  .modal-body {
    padding: 20px;
  }
}

.acc-2-habit-bg {
  background: lighten($indigo, 15%);
  color: $gray-900;
}

.acc-2-align-bg {
  background: $yellow;
  color: $gray-900;
}

.acc-vertical-card {
  display: flex;
  flex-direction: column;

  p {
    line-height: 16px;
  }

  ol,
  ul {
    line-height: 18px;
    padding-right: 0;
  }

  li {
    margin-bottom: 10px;
  }

  .acc-how-it-works & {
    height: 474px;
  }
}

.acc-card-top {
  flex: 0 0 auto;
}

.acc-ul-pop-habit {
  .fa-li {
    font-size: 20px;
    color: $success;
  }
}

.acc-ul-howitworks-align {
  .fa-li {
    font-size: 12px;
    color: #262626;
    line-height: 20px;
  }
}

p.acc-habit-howitworks-p1-p {
  line-height: 26px;
  margin-top: 20px;
}

.modal-dialog.acc-welcome {
  width: 600px;
  height: 550px;
}

.modal-dialog.acc-how-it-works {
  width: 344px;
}

.acc-left-right-line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

a.acc-small-card-link {
  font-size: 12px;

  .acc-icon-to-left-of-text {
    margin-right: 5px;
  }
}

.acc-explanation-modal,
.acc-align-guidance-modal {
  @extend .acc-welcome-modal;
}

.acc-explanation-modal,
.acc-align-guidance-view {
  &.blue {
    .modal-left {
      background-image: linear-gradient(#0d71e0, #0054cd);

      h3 {
        text-align: center;
        color: white;
      }
    }
  }

  h4 {
    color: $navy-dark;
  }

  .scatterplot-container {
    max-width: 28rem;
    margin: auto;
  }

  .modal-left {
    background-image: linear-gradient(149deg, #3023ae, #c86dd7 450px, #c86dd7);
    border-radius: 5px 0 0 5px;

    @include media-breakpoint-only(sm) {
      border-radius: 5px 5px 0 0;
    }

    border: 0;
    color: white;

    h3 {
      font-weight: normal;
      text-align: left;
      color: white;
    }

    svg {
      background: #ffffff;
    }
  }

  .modal-body {
    font-size: 14px;
    background: #ffffff;
    border-radius: 5px;
  }

  .nav.nav-tabs {
    border-bottom: solid 1px rgb(151 151 151 / 34%);
    margin-bottom: 20px;

    .nav-link,
    .nav-link:hover {
      background: transparent;
      color: rgb(0 0 0 / 38%);
      border: none;
    }

    .nav-link.active {
      background: none;
      position: relative;
      border: none;
      color: #000000;

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 4.5px;
        background: #4a90e2;
        border-radius: 7px;
      }
    }
  }
}

.acc-modal-fit-vertical {
  .modal-body {
    overflow-y: scroll;
    max-height: 80vh;
  }
}

@media (min-width: 800px) {
  .modal-dialog.acc-welcome {
    max-width: 600px;
  }
}

// @TODO ugh, we really should consolidate our modal styles
.acc-angela-modal {
  .modal-content {
    border-radius: 7px;
  }

  .close {
    position: absolute;
    top: 13px;
    right: 20px;
    font-size: 28px;
    font-weight: 300;
  }

  .modal-body {
    padding: 37px 50px;
  }

  .btn-lg.acc-btn-cta {
    padding: 0.5rem 2.75rem;
    line-height: 1.9;
  }

  h1 {
    font-size: 26px;
    font-weight: 800;
    color: #2f2f2f;
  }
}

.acc-big-modal-button {
  padding: 1em 1.5em;
}

.acc-modal-nav {
  line-height: 22px;
  vertical-align: middle;
}

// fight specificity
.acc-create-align-modal.acc-create-align-modal {
  font-size: $font-size-base;
  color: $body-color;

  .acc-text-secondary {
    font-size: 14px;
    color: $gray-800;
  }
}
