@import 'mixins';
@import 'resources/assets/sass/bootstrap_variables';

$acc-border-radius: 6px;
$acc-card-width-sm: 320px;
$acc-sidenav-width: 250px;
$acc-card-padding: 20px;
$acc-card-margin: 20px;

.acc-nav-link-icon {
  margin-right: 10px;
}

// New SVG icons from Ilona
img.acc-nav-link-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: bottom;
  opacity: 0.5;
  margin-right: 10px;
}

a:hover,
a:active,
.active {
  img.acc-nav-link-icon {
    opacity: 1;
  }
}

.topnav {
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid $gray-300;
  position: relative;

  .topnav__tinylabel {
    position: absolute;
    top: -6px;
    color: $gray;
    font-size: 11px;

    @include media-breakpoint-up(md) {
      left: 0.5rem;
    }
  }
}

.app-container {
  display: flex;
}

.admin-content-container {
  width: 100%;
}

.acc-disabled-link {
  pointer-events: none;
  cursor: default;
}

.acc-single-card {
  min-width: 400px;
  padding-left: 10px;
  padding-right: 10px;
}

.acc-card-left-image {
  max-height: 150px;
  margin: -10px auto 10px;
}

.acc-md-center-items {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
  }
}

.acc-dashboard-text {
  text-align: center;
}

.acc-dashboard-center-block {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.acc-small {
  font-size: 12px;
}

.acc-big {
  font-size: 20px;
}

.acc-shadow-0 {
  box-shadow: none;
}

.acc-shadow-1 {
  box-shadow: 0 7px 5px -5px rgb(0 0 0 / 10%);
}

.acc-shadow-4 {
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 12%);
}

.acc-small-link {
  font-size: 12px;
}

.acc-nonbold-link {
  font-weight: normal;
}

.acc-plain-link {
  color: $primary-light;

  &:visited:not([role='button']) {
    color: $primary;
  }

  &:hover,
  &:focus {
    color: $primary-darker;
    text-decoration: none;
  }

  &:active {
    // There wasn't state styles in mockup, so I made up, feel free to change it
    position: relative;
    top: 1px;
  }

  .disabled {
    cursor: default;
  }
}

.acc-link-margins {
  margin-left: 20px;
  margin-right: 20px;
}

.acc-dark-link {
  color: $navy;

  &:visited {
    color: $navy;
  }

  &:hover,
  &:focus {
    color: $primary-darker;
    text-decoration: none;
  }
}

a {
  .acc-visible-if-link-hover {
    visibility: hidden;
    padding-left: 0.5rem;
    float: right;
  }

  &:hover,
  &:focus {
    .acc-visible-if-link-hover {
      visibility: visible;
    }
  }

  // Fix IE-11 clearing issue floated external link indicator
  &.dropdown-item {
    overflow: hidden;
  }
}

.acc-text-grey {
  color: $gray-800;
}

.acc-nonbold {
  font-weight: normal;
}

.acc-nowrap {
  display: inline-block;
}

.acc-nowrap-flex {
  display: inline-flex;
}

.acc-btn-beside-header {
  position: relative;
  bottom: 5px;
  margin-left: 10px;
}

.acc-muted-text {
  color: $gray;
  font-size: 14px;
  line-height: 17px;
}

.acc-wrapping-card {
  border: 0.3px solid $border-color;
  border-radius: $acc-border-radius;
  background-color: $white;
  margin: $acc-card-margin;

  @extend .acc-shadow-1;

  width: $acc-card-width-sm;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }
}

.acc-card-sm {
  width: 280px;
}

.acc-nowrap-card {
  @extend .acc-wrapping-card;

  display: inline-flex;
  flex-shrink: 0;
}

.acc-card-content {
  padding: 15px $acc-card-padding;
  flex: 1 0 auto;
}

.acc-card-footer {
  padding: 0 $acc-card-margin $acc-card-margin $acc-card-margin;
}

hr.acc-card-width {
  margin: 15px (-$acc-card-padding) 15px (-$acc-card-padding);
}

@mixin acc-wrapping-row($cardMargin) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$cardMargin;
  margin-right: -$cardMargin;
}

.acc-wrapping-card-row {
  @include acc-wrapping-row($acc-card-margin);
}

.acc-card-row-centered {
  justify-content: center;
}

.acc-nowrap-card-row {
  @include acc-wrapping-row($acc-card-margin);

  flex-wrap: nowrap;
}

.acc-card-emphasized {
  @extend .acc-shadow-4;
}

@mixin lessHrGap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.acc-dash-section {
  margin-top: 40px;
  margin-bottom: 60px;

  &.acc-first-section {
    margin-top: 1em;
  }

  & > hr {
    @include lessHrGap;
  }
}

.acc-section-smaller-gap {
  margin-top: 20px;
  margin-bottom: 30px;
}

.acc-dash-row {
  margin-top: 40px;
  margin-bottom: 40px;

  & > hr {
    @include lessHrGap;
  }
}

$acc-dash-box-margin: 10px;

.acc-dash-box-row {
  @include acc-wrapping-row($acc-dash-box-margin);
}

.acc-dashbox-row-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.acc-dash-box {
  height: 112px;
  width: 200px;
  padding: 10px;
  margin: $acc-dash-box-margin;
  border: 0.3px solid $border-color;
  border-radius: 6px;
  background-color: $white;

  @extend .acc-shadow-1;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
}

.acc-heading-row {
  margin-top: 2em;
  border-bottom: 1px solid $border-color;

  & > h4,
  & > h5 {
    margin-bottom: 3px;
  }

  // Little blue rectangle at left end of line... copied from http://light.pinsupreme.com/index.html
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    background-color: $primary;
    width: 25px;
    height: 4px;
    border-radius: 0;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
  }
}

.modal .modal-content {
  border-radius: 10px;
}

.acc-modal-sm {
  width: $acc-card-width-sm;

  @include media-breakpoint-down(xs) {
    margin: 30px auto; // Keep the centering margins from bootstrap's sm/md/lg
  }
}

@mixin acc-product-header($productColor) {
  height: 74px;
  border-bottom: 1px solid $productColor;
}

.acc-card-header-align {
  position: relative;

  @include acc-product-header($primary);
}

.acc-card-header-perspective {
  @include acc-product-header($success);
}

.acc-card-header-habit {
  position: relative;

  @include acc-product-header($indigo);
}

.acc-page-header {
  margin-left: -40px;
  margin-right: -40px;
  position: relative;
  bottom: 40px;
}

.acc-team-section-header {
  padding: 10px 0;
  height: auto;
  background-size: 69px;

  h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.popover {
  font-size: 14px;
  line-height: 22px;
}

.acc-postit-popover {
  width: $acc-card-width-sm;
  max-width: $acc-card-width-sm;
  border-radius: $acc-border-radius;
  background-color: lighten($yellow, 30%);
  border: none;

  @extend .acc-shadow-1;

  font-weight: bold;
  margin-top: 22px;
  z-index: 1039;

  .acc-arrow {
    position: absolute;
    left: 50%;
    top: -23px;
    font-size: 20px;
    font-weight: normal;
    opacity: 0.8;
  }

  .acc-popover-content {
    padding: 10px;
    display: flex;
  }

  .popover-content {
    padding: unset;
    margin-left: 20px;
    display: inline-block;
  }

  .close {
    position: relative;
    bottom: 6px;
    align-self: flex-start;
    margin-left: auto;
    font-size: 20px;
    color: $navy;
  }
}

.acc-postit {
  background-color: lighten($yellow, 15%);
  border: none;

  @extend .acc-shadow-1;

  font-weight: bold;
  padding: 10px;
  display: inline-flex;

  .acc-postit-content {
    padding: unset;
    margin-left: 20px;
    display: inline-block;
  }
}

@mixin acc-tag {
  // Not yet: font-family: "Museo Sans";
  height: 14px;
  border-radius: 2px;
  font-weight: normal;
  padding: 0 3px;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  display: inline-block;
  margin: 0 5px;
}

.acc-tag-on-white {
  @include acc-tag;

  background-color: $gray-400;
  color: $white;
}

.acc-tag-on-dark {
  @include acc-tag;

  background-color: $gray-800;
  color: $navy;
}

.acc-table {
  th {
    @extend .acc-muted-text;

    font-weight: normal;
  }

  td {
    color: $gray-800;
    border-color: $gray-200;
  }

  & > thead > tr > th {
    border: none;
  }

  tbody > tr {
    background: $white;
  }

  .acc-inactive-row {
    td {
      @extend .acc-muted-text;
    }

    .acc-plain-link,
    .btn {
      filter: grayscale(80%);
      opacity: 0.5;
    }
  }
}

.acc-table-sticky {
  thead {
    position: sticky;
    top: 0;
  }
}

.acc-table-sticky-page {
  thead {
    position: sticky;
    top: 60px;
  }
}

[data-toggle='collapse'] {
  &.collapsed .acc-if-expanded {
    display: none;
  }

  &:not(.collapsed) .acc-if-collapsed {
    display: none;
  }
}

.acc-table-date-col {
  width: 200px;
}

.acc-table-manage-col {
  width: 80px;
}

.acc-table-results-col {
  width: 100px;
}

.acc-position-wrapper {
  position: relative;
}

.acc-bottom-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.acc-rightalign-3char {
  min-width: 3ch;
  text-align: right;
  display: inline-block;
  text-decoration: inherit;
}

.acc-not-linky {
  text-decoration: none;
  font-weight: normal;
  color: $gray-800;
}

.inactive-card {
  * {
    filter: grayscale(50%);
    opacity: 0.5;
  }
}

.acc-hack-bigger-check-click {
  width: 100%;
  cursor: pointer;
  padding-left: 10px;

  & > input[type='checkbox'] {
    cursor: inherit;
  }
}

.acc-card-row {
  display: flex;
  flex-grow: 1;

  .acc-card-section {
    display: flex;
    flex-direction: column;

    .acc-exercise-card {
      flex-grow: 1;
    }
  }
}

.card.acc-index-lookalike-card {
  margin: 15px;
  border: none;
}

.acc-index-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 225px;
  margin: 6px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(63 63 63 / 27%);
  border: solid 1px $border-color;
  text-align: center;
  padding: 15px;
  background: $white;

  &-highlight {
    box-shadow: 0 3px 5px 0 rgb(63 63 63 / 31%);
    border: solid 3px $border-color;
  }
}

.acc-index-card-wider {
  width: 310px;
}

.acc-index-card,
.card.acc-index-lookalike-card {
  .acc-card-graphic {
    border: solid 2px $border-color;
    border-radius: 50%;
    margin-bottom: 5px;
    clip-path: circle(109px at center);
  }

  .acc-card-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .acc-card-description {
    min-height: 4em;
    font-size: 13px;
    line-height: 1.14;
    margin-bottom: 0;
  }

  .acc-card-more {
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    color: #22c9b7;
  }

  .btn,
  .btn-primary {
    margin-top: 7px;
    min-width: 142px;
    padding: 7px 10px;
  }

  .acc-index-card-card-top-right {
    width: auto;
    padding: 0 10px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 3px;
    border-top-right-radius: 8px;
  }
}

.acc-card-top-right-tag {
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: $card-border-radius;
}

.acc-colored-card {
  @extend .acc-index-card;

  border: none;

  .acc-card-graphic {
    border: solid 2px $border-color;
  }

  .acc-card-more {
    color: #507ce5;
  }
}

.acc-manage-exercise-card {
  @extend .acc-index-card;

  align-items: flex-start;
  padding: 0;
  width: 315px;
  text-align: left;

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;

    &.status {
      color: #888888;
    }
  }

  i.done {
    color: #1abc9c;
  }

  i.in-progress {
    color: #f1c40f;
  }

  > div {
    padding: 14px;
  }

  .manage-buttons {
    a:not(:last-child) {
      margin-right: 10px;
    }
  }

  .btn {
    min-width: 100px;

    &.btn-lg {
      line-height: 1.5;
      font-size: 14px;
    }

    &.btn-success {
      color: white;
    }
  }

  .results,
  .track-progress,
  .coaches-view {
    i {
      color: #2980b9;
    }

    border-top: 1px solid $border-color;
  }

  .stats {
    span:not(:last-child) {
      margin-right: 30px;
    }

    .participant-count {
      color: #2980b9;
    }
  }
}

.potential-blindspots {
  &.disabled {
    filter: blur(4px);
  }

  h4 {
    font-size: 1.15rem;
    line-height: normal;
    color: $navy-dark;
  }

  .team-report-link {
    font-size: 1rem;
    line-height: normal;
    text-align: right;
    color: $primary;
  }

  & > a {
    &:hover {
      text-decoration: none;
      color: black;
    }

    & > div {
      padding: 14px;
    }
  }

  .tilted {
    color: $warning;
  }

  .balanced {
    color: $success;
  }

  .blindspot {
    color: $red;
  }

  .text-secondary {
    font-size: 0.85rem;
  }

  .dimension-bar {
    width: 100%;
    height: 0.75rem;
    border-radius: 2px;
    background-color: grey;

    &.tilted {
      background-color: $warning;
    }

    &.balanced {
      background-color: $success;
    }

    &.blindspot {
      background-color: $red;
    }
  }

  .scale-col {
    width: 5rem;
    margin-right: 2rem;
    flex-shrink: 0;
  }

  .status-col {
    width: 4rem;
    margin-left: 1rem;
    text-align: right;
    flex-shrink: 0;
  }

  footer: {
    height: 315px;
  }
}

.acc-track-progress-card {
  @extend .acc-manage-exercise-card;

  justify-content: flex-start;

  .run-month {
    color: #2980b9;
    font-weight: bold;
  }
}

.acc-card-placeholder {
  width: 255px;
}

.acc-welcome-header {
  display: flex;
  align-items: flex-start;
  max-width: 850px; // have to do this because container is set to a fixed width
  padding-bottom: 20px;
  border-bottom: solid 1px $border-color;

  h3 {
    font-size: 18px;
    white-space: nowrap;
    color: #4a4a4a;
  }

  p {
    margin: 0 20px;
    color: #898989;
  }
}

.acc-dashboard-header {
  max-width: 850px;
  margin-top: 40px;
  border-bottom: solid 1px $border-color;
  margin-bottom: 20px;
}

.acc-card-tooltip {
  display: block;
  position: absolute;
  top: -15px;
  transform: translateY(-100%);
  width: 120px;
  padding: 8px;
  font-size: 11px;
  line-height: 1.27;
  text-align: center;
  background: #2c3540;
  color: $white;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  z-index: 99;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-top: solid 10px $navy;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

// a little trick to override more specific styles
.acc-card-divider.acc-card-divider {
  text-align: center;
  font-size: 18px;
  height: 20px;
  font-weight: normal;
  color: #4a4a4a;
  border-bottom: solid 1px $gray-600;
  margin: 20px 10px 25px;

  span {
    background: $gray-200;
    display: inline-block;
    padding: 8px 11px 0;
  }
}

.acc-share-link {
  padding: 10px 0;

  &.with-borders {
    border-top: solid 1px $border-color;
    border-bottom: solid 1px $border-color;
  }

  .lead {
    color: #898989;
  }

  input[type='text'] {
    font-size: 18px;
    border: dashed 1px #7e7e7e;
    border-right: none;
    padding: 10px;
    width: 300px;
    color: #626262;
    height: 40px;
    vertical-align: bottom;

    & + button {
      width: 102px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  button {
    height: 40px;
    vertical-align: bottom;
    font-size: 16px;
  }
}

.acc-share-link-v2 {
  display: inline-flex;
  width: 25rem;

  button {
    width: 6rem;
  }

  input {
    border: dashed 1px $gray-800;
  }
}

.acc-remind-me {
  font-size: 18px;
  text-decoration: underline;
  vertical-align: middle;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    background: center / 50% no-repeat url('/images/icons/remind-me@2x.png');
    width: 35px;
    height: 35px;
  }
}

.acc-well {
  border-radius: 5px;
  background: $gray-200;
  border: solid 1px lighten($primary, 30%);
  padding: 20px;

  hr {
    margin-top: 1em;
    margin-bottom: 1em;
    border-top: solid 1px lighten($primary, 30%);
  }
}

.acc-helpdesk-box {
  border-radius: 5px;
  background: lighten($primary, 30%);
  border: solid 1px lighten($primary, 30%);
  padding: 10px;
  color: $gray-800;
}

.acc-ul-selectable {
  padding-left: 0;
  list-style: none;

  li {
    font-size: inherit;

    label {
      display: flex;
    }
  }
}

.acc-ul-sortable {
  padding-left: 0;
  list-style: none;
  background: inherit;

  li {
    cursor: move;
    padding: 0.5em 1em;
    border: 1px solid #d0dfe7;
    border-radius: 3px;
    margin: 0.5em;
    background: inherit;

    .fa {
      margin-right: 1em;
    }
  }
}

.acc-children-full-width {
  & > * {
    width: 100%;
  }
}

.acc-quick-links > a {
  margin-top: 1em;
}

.see-other-align-templates {
  position: absolute;
  right: 10px;
  top: 0;
}

.acc-manage-org {
  white-space: normal;
}

.acc-manage-exercise {
  button[disabled],
  .disabled {
    filter: grayscale(100%);
  }
}

#get-started {
  button {
    width: 100%;
    height: 48px;
  }
}

@include media-breakpoint-up(md) {
  .acc-welcome-page-goal {
    width: 204px;
    height: 91px;
    margin-bottom: 1.5rem;
  }

  #get-started button {
    width: auto;

    &.next-button {
      float: right;
    }
  }
}

.acc-panel {
  @extend .shadow-sm;
  @extend .acc-index-lookalike-card;
  @extend .w-100;

  line-height: 1.4;

  h1 {
    font-weight: $font-weight-semi-bold;
    font-size: 16px;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  h2 {
    font-size: 14px;
    color: #6b6b6b;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  .acc-sub-desc {
    color: #aaaaaa;
    margin-bottom: 1.5rem;
    line-height: 1.4;
  }
}

.subnav {
  display: flex;
  align-items: center;
  background: $gray-200;
  padding: 10px 15px;
  min-height: 69px;

  .subnav-nav-left {
    @include media-breakpoint-down(md) {
      a {
        display: block;
        padding-bottom: 0.5rem;
      }
    }
  }
}

.acc-dash-heading {
  font-size: 18px;
  font-weight: $font-weight-semi-bold;
}

.acc-dash-widget {
  background: $white;
  box-shadow: 0 1px 20px rgb(173 181 189 / 20%);
  border-radius: 5px;
  padding: 2rem;
  color: $gray-800;

  &.acc-dash-widget--light-blue {
    background-color: #f0f8ff;

    *,
    h5 {
      color: #000000;
    }

    .btn.btn-text {
      color: $primary;
    }
  }

  &.card {
    border: 0;
  }

  &.acc-dash-widget--light-blue {
    background-color: #f0f8ff;

    *,
    h5 {
      color: #000000;
    }

    .btn.btn-text {
      color: $primary;
    }
  }

  &.acc-dash-widget--cyan {
    background-color: $cyan;

    * {
      color: #ffffff;
    }

    .btn.btn-primary {
      background-color: #ffffff;
      border: 1px solid $gray-300;
      border-radius: 3px;
      color: $gray-800;
    }

    .btn.btn-secondary {
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 3px;
      color: #ffffff;
    }
  }

  &.acc-dash-widget--no-shadow {
    box-shadow: none;
  }

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    &.divided {
      border-bottom: 1px solid #e9ecef;
    }

    .acc-dash-heading {
      margin-bottom: 0;
    }
  }

  .padded {
    padding: 1rem 2rem;
  }
}

.acc-dash-stats {
  background: #f8f9fa;
  color: #6b6b6b;

  table {
    width: 100%;
  }

  td:first-child {
    padding-right: 1rem;
  }

  caption {
    caption-side: top;
    font-weight: $font-weight-semi-bold;
    color: $gray;
    padding-bottom: 0.25rem;
  }

  :first-child caption {
    padding-top: 0;
  }
}

.acc-dash-right-sidebar {
  .acc-dash-widget {
    box-shadow: none;
    border-radius: 3px;
    padding: 25px;
  }
}

.acc-dash-table {
  @extend .table;
  @extend .table-striped;

  border-collapse: separate;
  border-spacing: 0;

  thead {
    background: $white;
  }

  td {
    font-size: 1rem;
    border-top: none;
    vertical-align: middle;
  }

  th,
  thead th {
    font-weight: normal;
    font-size: 14px;
    color: $gray;
    border: none;
    border-bottom: 1px solid #eaeaea;

    input[type='checkbox'] {
      font-size: 1rem;
    }
  }

  .acc-muted-status {
    color: #b1b1b1;
  }

  .dropdown-toggle {
    @extend .text-nowrap;
  }

  &.empty {
    thead th {
      border-bottom: 0;
    }
  }
}

.acc-sortable-table {
  thead th {
    cursor: default;
  }
}

.acc-breadcrumb {
  color: $gray;

  .acc-breadcrumb-separator {
    margin: 0 1rem;
  }

  .acc-breadcrumb-item {
    color: $gray;
    white-space: nowrap;

    a {
      color: $gray;
    }

    a:hover {
      cursor: pointer;
      color: darken($gray, 15%);
    }

    &.active,
    &.active a,
    &.active span {
      font-weight: $font-weight-semi-bold;
      color: $gray-900;
    }
  }
}

.acc-align-text-orange {
  color: #ff7b1b;
}

.acc-align-text-green {
  color: #20c9ab;
}

.acc-dash-teaser {
  @extend .acc-dash-widget;

  padding: 0;

  .lead {
    color: inherit;
    font-size: 16px;
    line-height: 22px;
  }
}

.acc-align-intro {
  display: flex;
  justify-content: between;
  padding: 30px 25px;
  background: #f1f9f8;
  border-radius: 0 5px 5px 0;

  .figure {
    flex: 1 1 0;
    margin: 0;
    text-align: center;

    .figure-img {
      margin: 0;
    }

    .figure-caption {
      margin: 0.5rem;
      font-size: 14px;
      color: #6b6b6b;
    }
  }
}

.acc-pulse-review {
  font-size: 1rem;
}

.acc-pulse-dash-links {
  @extend .text-right;

  width: 8rem;
}

.acc-link-lg {
  font-weight: $font-weight-semi-bold;
  font-size: 16px;
  line-height: 22px;
}

.acc-selectable-card {
  border: 1px solid #ededed;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  width: 175px;
  padding: 18px 12px 0;
  text-align: center;

  i.selected {
    color: #3789e4;
    position: absolute;
    top: 3px;
    right: 9px;
    font-size: 10px;
  }

  .acc-card-title {
    font-size: 14px;
    text-align: center;
    margin: 13px 0 5px;
    font-weight: normal;
  }

  .acc-card-description {
    font-size: 12px;
    line-height: 1.3;
    color: $gray;
  }

  &.active {
    border: 1px solid #3789e4;
  }
}

.acc-dash-promo {
  font-size: 16px;
  border-radius: 5px;
  padding: 0;
  margin: 23.5px 0;
  background: $white;
  box-shadow: 0 1px 4px rgb(0 0 0 / 16%);

  .figure-caption {
    color: #6b6b6b;
    font-size: 16px;
  }

  .acc-dash-bg-grayblue-light {
    border-radius: 0 5px 5px 0;
    height: 100%;
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }
}

.acc-dash-promo-padding {
  padding: 30px;
}

.acc-dash-bg-grayblue-light {
  background: #f7fbff;
}

.acc-dashboard-nav {
  .nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    color: $gray;

    i {
      display: inline-block;
      width: 32px;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    background: none;
    color: $primary;
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

@include media-breakpoint-down(md) {
  .acc-dashboard-nav-breadcrumbs {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.stats-widget {
  font-size: 16px;

  .stats-box {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    i {
      display: inline-block;
      min-width: 2rem;
      text-align: center;
      color: $primary;
      vertical-align: middle;
      font-size: 1.25rem;
    }

    .stats-title {
      color: $gray;
      flex: 1;
      margin-left: 1rem;
    }

    .stats-number {
      color: $gray-800;
      min-width: 2.5rem;
      text-align: right;
    }
  }
}

.acc-accordion {
  padding: 30px 25px 40px;

  .sidebar-title {
    margin-bottom: 12px;
    color: $gray;
  }

  .card-header {
    background-color: #ffffff;

    .btn-link {
      &.collapsed {
        color: $gray;

        &:hover {
          color: $primary;
        }
      }
    }

    padding: 7px;
  }
}

.learn-how-hints {
  .learn-how-cards {
    padding-left: 5px;
  }

  .learn-how-card {
    border: 1px solid $gray-400;
    border-radius: 4px;
    width: 170px;

    .card-content {
      padding: 15px;

      a {
        display: block;
        font-weight: $font-weight-semi-bold;
        margin-top: 10px;
      }
    }
  }
}

.welcome-top-section {
  text-align: center;

  .team-illustration {
    img {
      height: 112px;
    }
  }

  .enterprise-logo {
    img {
      &.logo-org {
        width: 115px;
        height: 115px;
      }

      &.logo-shift {
        height: 40px;
        margin-right: 0.5rem;
      }
    }

    h2 {
      display: inline;
    }
  }

  .title-start-guide {
    text-transform: uppercase;
    color: #2f2f2f;
    font-weight: $font-weight-semi-bold;
    margin: 13px;
  }
}

.no-content {
  padding: 40px;
  text-align: center;
}

#add-coach-client-modal {
  .modal-footer {
    margin-top: 75px;
  }
}

.status-circle {
  &.status-completed {
    color: $teal;
  }

  &.status-started {
    color: $orange;
  }

  &.status-ran-again {
    color: $indigo;
  }

  &.status-not-started {
    color: #eaeaea;
  }
}

ul.graph-legend {
  padding: 0;
  margin: 0;
  position: relative;
  top: -10px;

  li {
    list-style: none;
    height: 40px;

    &::before {
      content: '•';
      font-size: 55px;
      padding-right: 7px;
      position: relative;
      color: $gray-300;
    }

    &.legend-align::before {
      color: $primary;
    }

    &.legend-habit::before {
      color: $indigo;
    }

    &.legend-perspective::before {
      color: $teal;
    }

    span {
      position: relative;
      top: -9px;
    }
  }
}

.graph_wrapper {
  position: relative;
  margin-top: 14px;
}

.graph_overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 70px;

  .count {
    width: 100%;
    font-size: 30px;
    font-weight: bold;
  }

  .total {
    width: 100%;
    font-size: 14px;
  }
}

.checkin-sidebar {
  .checkin-template {
    .checkin-template-header {
      font-weight: bold;
      cursor: pointer;
      color: #2f2f2f;
      min-height: 30px;
      line-height: 30px;
    }

    .checkin-category {
      display: none;
    }

    .checkin-category-header {
      font-weight: $font-weight-semi-bold;
      color: #2f2f2f;
      padding-left: 20px;
      margin: 13px 0 10px;

      a.select-all {
        font-size: 12px;
        line-height: 25px;
      }
    }

    .checkin-question {
      padding: 0 20px;
      margin-bottom: 5px;
      color: $gray;
      cursor: pointer;

      &.active {
        background-color: $gray-300;
      }
    }

    &.active {
      .checkin-category {
        display: block;
      }
    }
  }
}

.modal-steps-nav {
  .modal-step {
    cursor: default;

    &:not(:first-child) {
      margin-left: 20px;
    }

    color: $gray-600;

    .badge-pill {
      background-color: $gray-600;
    }

    &.active {
      color: $primary;

      .badge-pill {
        background-color: $primary;
      }
    }
  }

  .badge {
    margin-right: 4px;
  }
}

.launch-screen {
  .arrow {
    padding-top: 63px;
  }
}

.invite-option-wrap {
  border: 1px solid $gray-300;
  padding: 34px;
  border-radius: 3px;
  margin: 20px 0;
  text-align: left;

  .invite-icon {
    color: $primary;
    margin-right: 12px;

    &.gray {
      color: $gray-500;
    }

    &.green {
      color: $green;
    }
  }
}

.profile-step {
  .fa-circle,
  .profile-step-headline {
    color: $primary;
  }

  .fa-check {
    color: $green;
  }

  .fa-arrow-right {
    color: $gray;
  }

  &.profile-step-active {
    .profile-step-headline {
      font-weight: bold;
    }
  }

  .profile-widget {
    cursor: default;
  }
}

.habit-widget-recurrence {
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  color: $gray;
}

.scrollable-300 {
  height: 300px;
  overflow-y: auto;
}

$avatarSize: 2rem;

.avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: $avatarSize;
  height: $avatarSize;
  border-radius: 0.25rem;
  margin-right: 0.25rem;

  &.anonymous {
    background: $gray-300;

    span {
      color: $gray;
    }
  }
}

.avatar span {
  font-size: 0.85rem;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0 0 0 / 50%);
}

.avatar-custom-color-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  margin-bottom: 0.5rem;

  input {
    margin-right: 0.5rem;
  }

  .avatar-custom-color-box-preview {
    display: inline-block;
    width: 2rem;
    height: 1rem;
  }
}

.avatar-alignment-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  .avatar-alignment-flex {
    flex: 1;
    margin-left: 0.5rem;
  }
}

.answer-selection {
  @extend .panel--gray-light;
  @extend .p-md-5;
  @extend .py-3;

  h3,
  p {
    text-align: center;
  }

  h3 {
    font-size: 1.4rem;
    color: $navy-dark;
  }

  .answer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 102px;
    border: 1px solid $gray-400;
    color: $gray;
    border-radius: 5px;
    background: $white;
    position: relative;

    &.active {
      font-weight: 600;
      border-color: $primary;
      color: $primary;
    }

    &.disabled {
      cursor: not-allowed;
    }

    span {
      font-size: 1rem;
      text-align: center;
      line-height: normal;
    }

    .checkmark {
      position: absolute;
      top: 2px;
      right: 10px;
      height: 12px;
      width: 12px;
    }
  }

  .btn {
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.tab-nav-wrapper {
  border-bottom: 1px solid $gray-300;
  margin-bottom: 35px;

  .tab-actions {
    display: none;

    &.active {
      display: flex;
    }

    .btn {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}

.container-full .tab-nav-wrapper {
  margin-bottom: 0;

  @extend .px-4;
  @extend .px-md-5;
}

.tab-nav {
  .nav-link {
    padding-left: 0;
    padding-right: 0;
    color: $gray;

    .badge {
      padding-left: 10px;
      padding-right: 10px;
      background: $gray-300;
      font-weight: normal;
    }
  }

  .active {
    font-weight: 600;
    border-bottom: 3px solid $primary;
    color: $primary;
  }
}

.summary-tab {
  h2 {
    @extend .mb-4;

    font-size: 1.4rem;
    color: $navy-dark;
  }

  .card {
    border-color: $gray-200;
    border-radius: 4px;
    min-height: 339px;

    .card-img-top {
      height: 129px;
      background: #f0f8ff;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      &.habit {
        align-items: center;
      }

      &.light-gray {
        background: lighten($gray-200, 2%);
      }
    }

    .card-title {
      font-size: 1rem;
      line-height: normal;
      color: $navy-dark;
    }

    .card-action {
      font-weight: 600;
    }
  }
}

.gray-block {
  @extend .panel--gray-light;
  @extend .widget__content;
  @extend .mb-3;

  h5,
  p {
    color: $gray;
  }

  .title {
    font-size: 1rem;
    color: $gray;
    font-weight: $font-weight-semi-bold;
  }

  ul {
    @extend .p-0;

    list-style: none;

    &:last-child {
      @extend .m-0;
    }

    li .count {
      font-weight: 600;
    }
  }
}

/* Mobile Nav */

.top-navbar-collapse-inner {
  width: 100%;
  display: flex;
  flex-basis: auto;
  align-items: center;
}

@include media-breakpoint-down(md) {
  .top-navbar-collapse-inner {
    display: block;
    background: $blue-500;
    padding: 0;
  }

  .navbar .top-navbar-collapse {
    &.show,
    &.collapsing {
      position: absolute;
      top: 3.3rem;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $zindex-dropdown;
      overflow-y: scroll;
    }

    &.show {
      height: fit-content;
    }
  }

  .navbar-nav {
    display: flex;
    justify-self: center;
    align-items: flex-start;
    text-align: center;
    margin-right: 0;
    padding-right: 0;

    .nav-btn {
      width: 100%;
      margin-right: 0 !important;
    }

    .nav-item.dropdown {
      margin: auto;
      width: 100%;

      &.member-dropdown {
        margin: 1rem auto;
      }
    }
  }
}

.navbar-nav {
  .member-dropdown .dropdown-menu {
    min-width: 11rem !important;
  }
}

.manager-view {
  display: flex;
  flex-direction: column;
}

.empty-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 0 31px;

  .message {
    font-size: 1.4rem;
    font-weight: normal;
    color: $gray;
    margin: 26px 0 13px;
  }

  .help-text {
    font-size: 1rem;
    font-weight: normal;
    color: $gray;
    margin-bottom: 16px;
  }
}

.recent-activity {
  list-style: none;
  padding: 0;
  margin-bottom: 10px;

  .item {
    display: flex;

    .avatar-wrapper {
      margin-right: 14px;
    }

    .avatar-wrapper + div {
      text-align: left;
    }
  }
}

.team-activity {
  @extend .gray-block;

  .title {
    color: $gray;
    font-size: 1rem;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 1rem;
  }

  .scrollstatic,
  .scrollable {
    height: 340px;
    padding-bottom: 30px;
    overflow-y: auto;

    .items {
      @extend .recent-activity;

      position: relative;

      .loading {
        text-align: center;
        position: absolute;
        bottom: -30px;
        width: 100%;
      }
    }
  }
}

.activity-date {
  font-size: 0.7rem;
  color: $gray-600;
  margin-bottom: 10px;
  font-weight: 600;
}

.checklist {
  padding: 0;
  margin: 0 0 0 6px;
  list-style: none;

  li {
    margin-bottom: 15px;
    color: $gray;

    a {
      color: $gray;

      &.next {
        color: $primary;
        font-weight: 600;
      }
    }

    &.done {
      color: $gray-500;
    }
  }

  .check-icon {
    margin-right: 10px;

    i:first-child {
      background: $gray-200;
    }
  }
}

.panel--title {
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: $gray;
  font-weight: $font-weight-semi-bold;
}

.activity-statements-widget {
  img.splash-icon {
    max-width: 13.75rem;
    margin: auto;
  }

  h6 i {
    position: relative;
    top: 1px;
    left: 2px;
  }

  ul.statement-list {
    margin-top: 0.5rem;

    li {
      color: $gray-800;
      margin-bottom: 0.75rem;

      .statement-list-exercise-type {
        color: $gray;
        font-size: 0.875rem;
      }
    }
  }
}

.org-dashboard-logo {
  width: auto;
  max-height: 80px;
  float: right;
}

.content-card {
  @extend .widget;

  .title {
    @extend .border-0;

    padding: 30px;
    font-size: 1.125rem;
    font-weight: $font-weight-semi-bold;
    color: $navy-dark;
  }

  .content {
    @extend .widget__content;
    @extend .pt-0;

    text-align: left;

    .row:nth-last-child(2) .wrapper {
      @extend .border-0;
      @extend .m-0;
      @extend .p-0;
    }

    .wrapper {
      @extend .pb-3;
      @extend .mb-3;

      border-bottom: 1px solid $gray-300;

      .activity {
        display: flex;

        .avatar {
          margin: 5px 13px 0 0;
        }
      }

      .meta {
        color: $gray;
        font-size: 0.9rem;

        .spacer {
          color: $gray;
          display: inline-block;
          margin: 0 8px;
        }

        .participants i {
          margin-right: 5px;
        }
      }
    }

    .scrollable {
      height: 387px;
      padding: 0 15px 40px 0;
      overflow-y: auto;

      .items {
        position: relative;

        .loading {
          text-align: center;
          position: absolute;
          bottom: -40px;
          width: 100%;
        }
      }
    }
  }
}

.modern-dash-table {
  @extend .table;
  @extend .table-responsive;
  @extend .mt-4;
  @extend .w-100;

  display: table;
  color: $navy;

  a {
    color: $primary;
  }

  td {
    border-top: 0;
    border-bottom: 1px solid #dee2e6;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.acc-dash-widget-modern {
  h4 {
    color: $navy-dark;
  }

  .acc-dash-widget-modern-empty-message {
    color: $gray;
    text-align: center;
    padding-top: 3rem;

    i {
      font-size: 3rem;
    }
  }
}

.bar-graph {
  margin-top: 30px;
  position: relative;

  .scrollable,
  .scrollstatic {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;

    .items {
      display: flex;

      &::before {
        content: '';
        width: 100%;
        border-bottom: 1px solid $gray-300;
        position: absolute;
        top: 136px;
      }

      .bar {
        height: 132px;
        width: 90px;
        padding: 0 40px 0 30px;
        margin-bottom: 30px;
        flex-shrink: 0;
        position: relative;

        .celebrate,
        .discuss {
          margin-bottom: 2px;
        }

        .diagnose,
        .discuss,
        .celebrate {
          text-indent: -999999px;
          height: 100%;
          width: 100%;
        }

        .empty {
          height: 100%;
          width: 100%;
          background: $gray-300;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          animation: blinker 1s linear infinite;

          @keyframes blinker {
            50% {
              opacity: 0;
            }
          }
        }

        :first-child {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
      }

      .diagnose {
        background: #fa4444;
      }

      .discuss {
        background: #ffc35c;
      }

      .celebrate {
        background: #1fcbad;
      }

      .date {
        position: absolute;
        bottom: -30px;
        left: 8px;
        font-size: 0.75rem;
        color: $gray;
        font-weight: $font-weight-semi-bold;
        text-align: center;
      }
    }
  }
}

.perspective-contents {
  text-align: center;
  margin: 10px;
  padding-top: 1px;
}

.my-perspective {
  h2 {
    font-size: 1.15rem;
  }

  .empty-message {
    font-weight: $font-weight-semi-bold;
    display: flex;
    align-items: center;

    & > span:first-child {
      min-width: 117px;
      margin-right: 10px;
    }
  }
}

.things-todo-next {
  .checklist {
    .circle {
      color: $gray-400;
    }

    .check {
      color: $white;
    }

    .circle-outline {
      color: $primary;
    }

    .check,
    .circle-outline {
      visibility: hidden;
    }

    .next {
      .circle {
        visibility: hidden;
      }

      .circle-outline {
        visibility: visible;
      }
    }

    .done {
      .circle {
        color: #20c9ab;
      }

      .check {
        visibility: visible;
      }
    }
  }

  li {
    display: flex;

    .icon-wrapper {
      width: 2rem;

      .check-icon {
        margin-right: 1rem;
      }
    }

    .wrapper {
      .label {
        display: flex;

        .label-inner {
          margin-right: 10px;
        }
      }

      p {
        font-size: 0.9rem;
        margin: 5px 0 0;
      }

      .btn {
        color: $white;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
}

.align-landing-with-perspective {
  @extend .widget;
  @extend .widget__content;

  text-align: center;

  .fa-user-circle {
    color: #878787;
  }

  .start-360 {
    @extend .btn;
    @extend .btn-primary;
    @extend .mt-3;
    @extend .mb-5;

    padding: 15px;
    min-width: 270px;
  }

  .create-team-button {
    @extend .btn;
    @extend .btn-primary;

    padding: 0.75rem 1rem;
    min-width: 17rem;
  }

  .how-it-works h5 {
    text-align: left;
    margin-bottom: 30px;
  }

  .blocks {
    text-align: left;
    display: flex;

    .block {
      padding: 20px 20px 0 0;
      border-top: 1px dashed $primary;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: $primary;
        border-width: 10px;
      }

      .title {
        color: $navy-dark;
        font-weight: $font-weight-semi-bold;
        font-size: 1rem;
        margin-bottom: 5px;
      }
    }
  }
}

.testimonials {
  .carousel-dots {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline;
      cursor: pointer;
      color: #c4c4c4;
      font-size: 12px;
      margin: 0 2px;

      &.active {
        color: $secondary;
      }
    }
  }

  .carousel-wrapper {
    display: flex;
    align-items: center;
    min-height: 10rem;
  }

  .carousel-item-content {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    text-align: center;
    min-height: 15rem;
  }

  .cite {
    color: $gray-800;
    font-weight: $font-weight-semi-bold;
    font-size: 0.75rem;
    text-align: center;
  }
}

.all-managers {
  h3 {
    font-size: 2.5rem;
    font-weight: $font-weight-semi-bold;
    line-height: 40px;
  }
}

.nav-pills.rounded-toggle-list {
  margin-top: 2rem;
  margin-right: 1rem;

  .nav-item .nav-link {
    border-radius: 2rem;
    padding: 0.125rem 0.65rem;
    color: $gray;

    &.active {
      color: #ffffff;
    }
  }
}

.activation-stats-table {
  tr {
    th:first-child {
      position: sticky;
      left: 0;
    }

    &:nth-child(odd) th:first-child {
      background: $table-accent-bg;
    }

    &:nth-child(even) th:first-child {
      background: $white;
    }
  }
}

.org-members-actions {
  form {
    margin: 0 1rem 0 0;

    input {
      width: 220px !important;
    }
  }
}

.how-it-works__title {
  text-transform: uppercase;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: #878787;
  margin: 2rem auto;
}

#activity-log > .logical_container {
  display: block;
}

.exercise-habit-divider {
  border-top: 1px solid $gray-300;
}
