.empty-state {
  padding: 2rem;
}

.empty-state--modern {
  &,
  p {
    color: $gray-800;
  }

  h1,
  h3,
  h6 {
    color: $navy-dark;
  }
}

.empty-state__description,
.empty-state__header {
  margin-bottom: 3rem;
}

.empty-state__description,
.how-it-works__step-image,
.how-it-works__step-text {
  text-align: center;
}

.how-it-works__step-image {
  margin-bottom: 1rem;
}

.how-it-works__step-title,
.empty-state__title {
  margin-bottom: 0.5rem;
}

.empty-state__header__flexbox {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;

    .empty-state__header__right {
      margin-bottom: 1rem;
    }
  }
}

.empty-state__node-list {
  margin-top: 1.5rem;

  .empty-state__node-list__content {
    display: flex;
    position: relative;
    top: -10.5px;
  }

  .empty-state__node-list__timeline {
    height: 1px;
    width: 100%;
    background-image: url('/images/align/align-pin-stripe.svg');
    background-repeat: repeat-x;
  }

  $nodeSize: 20px;

  .empty-state__node-list__node {
    background: $primary;
    width: $nodeSize;
    height: $nodeSize;
    border-radius: $nodeSize;
    margin-bottom: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .empty-state__node-list__item {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;

    .empty-state__node-list__timeline {
      height: 100%;
      width: 1px;
      background-image: url('/images/align/align-pin-stripe-down.svg');
      background-repeat: repeat-y;
      margin-left: 10px;
    }

    .empty-state__node-list__content {
      flex-direction: column;
    }

    .empty-state__node-list__content {
      top: 0;
      left: -0.5px;
    }

    .empty-state__node-list__timeline-container {
      flex-grow: 0;
      width: 1px;
    }

    .empty-state__node-list__item {
      display: flex;
      margin-right: 0;
    }

    .empty-state__node-list__node {
      margin-right: 1rem;

      h6 {
        margin-top: 1px;
      }
    }
  }
}

.empty-state__side-image-container {
  min-width: 12rem;
  margin-left: 3rem;

  img {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-bottom: 2rem;
    max-width: 100%;
    text-align: center;

    img {
      max-width: 16rem;
    }
  }
}
