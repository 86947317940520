// third party imports

// our imports
@import 'bootstrap_variables';
@import 'resources/assets/bootstrap/scss/bootstrap';
@import 'mixins';
@import 'limit-transitions';

$acc-perspective-team-you: $success;
$acc-perspective-team-other-member: #9e9e9e;

// Generate background classes for ALL $colors
//  (Bootstrap does this only for $theme-colors)
@each $color, $value in $colors {
  @include bg-variant('.bg-#{$color}', $value);
}

// ALL Text Colors (not just theme colors)
@each $color, $value in $colors {
  @include text-emphasis-variant('.text-#{$color}', $value);
}

// random other fixes on top of Bootstrap

// Override some of bootstrap Reboot styles that are set by variables.
// - Base $font-size-base is in REM so we need to set the inherited font size
body {
  font-size: $body-font;
  font-family: $font-family-default;
}

html {
  body {
    margin: 0;
  }

  &,
  body,
  #main-wrapper,
  #react-app {
    height: 100%;
  }
}

.badge {
  font-size: 12px;
}

a {
  cursor: pointer;
}

.panel--gray-light {
  background: lighten($gray-200, 1%);
  border-radius: 5px;

  &.with-border {
    border: 1px solid $gray-300;
  }
}

// natopnav

.navbar-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  float: none;
  line-height: 3.1;
  color: gray-700;

  a {
    color: gray-700;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    &.active {
      font-weight: bold;
      border-bottom: 4px solid lighten($yellow, 15%);
    }
  }
}

.modal-backfill {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  display: none;
  opacity: 0.5;
}

div.hamburger {
  width: 25px;
  height: 4px;
  background-color: $primary;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 15px;
  border-radius: 2px;
}

.dropdown-divider {
  &:first-child,
  &:last-child {
    display: none;
  }
}

.bg--pastel-blue {
  background: $pastel-blue;
  color: $info;
}

.bg--pastel-green {
  background: $pastel-green;
  color: $green;
}

.bg--pastel-indigo {
  background: $pastel-indigo;
  color: $indigo;
}

.bg--pastel-purple {
  background: $pastel-purple;
  color: $purple;
}

.bg--pastel-orange {
  background: $pastel-orange;
  color: $orange;
}

.ant-back-top {
  // z-index wars: We need to be higher than anything else except modal's
  // @todo end the z-index wars by reducing all other app z-indexes
  z-index: 1030;

  .ant-back-top-icon {
    background: $brand;
  }
}

// missing in our BS4 version ?
.overflow-auto {
  overflow: auto !important;
}

// SVG

circle {
  shape-rendering: auto;
}

rect {
  shape-rendering: crispedges;
}

.no-transition-for-testing {
  transition: none !important;
}

.ant-steps-finish-icon svg {
  vertical-align: baseline;
}

.ant-popover-buttons button {
  height: 1.75rem;
}

@import 'animations';
@import 'styles';
@import 'forms';
@import 'typography';
@import 'buttons';
@import 'align';
@import 'dashboard';
@import 'widget';
@import 'react-tabs';
@import 'page';
@import 'empty-state';
@import 'generic-modal';
@import 'acc-modal';
@import 'acc-ilona-modal';
@import 'acc-registration';
@import 'grayTopModal';
@import 'perspective';
@import 'layouts';
@import 'compatibility';
@import 'landingPage';
@import 'offsites';
@import 'print';
@import 'utils';
@import 'alert';
