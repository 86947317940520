// New design for modal dialogs

$acc-btn-teal: #5ac6ac;

.acc-ilona-modal {
  p {
    line-height: 1.4;
    font-size: 18px;
    font-weight: 300;
    color: $gray-800;
  }

  .lead {
    font-size: 24px;
    font-weight: 400;
    color: $gray-800;
  }

  .acc-inner-addon .fa {
    color: $gray-800;
  }

  label {
    margin-bottom: 2px;
    font-size: 12px;
    color: $gray-600;
  }

  .learn-more-checkbox {
    text-transform: none;
    font-size: 15px;
    font-weight: 300;
    color: $gray-800;
  }

  .modal-title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .modal-header .modal-title {
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 5px;
    min-height: 350px;
  }

  .modal-header {
    padding: 15px;
  }

  .modal-body {
    padding: 20px;
  }

  .modal-header + .modal-body {
    padding: 10px 20px 20px;
  }

  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    float: none;
    font-size: 24px;
    font-weight: normal;
  }

  .btn-link {
    color: $gray-600;
  }

  .btn-light {
    color: #ffffff;
  }

  input:checked ~ .checkbox-icon { display: none; }
  input:not(:checked) ~ .checkbox-icon-checked { display: none; }
}
