@import 'resources/assets/sass/bootstrap_variables.scss';
@import 'resources/assets/sass/_animations';

.shift-loading {
  background: $gray-300 !important;
  text-indent: -999999px;
  animation: fake-loader-pulse 2s infinite !important;
  box-shadow: none !important;

  & > * {
    visibility: hidden;
  }
}

body.with-gray-bg {
  background: $gray-100;
}
