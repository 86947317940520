.landing-page {
  .container {
    text-align: center;
    padding-top: 20px;

    h1 {
      color: white;
      margin-bottom: 30px;
    }

    .button-container {
      margin-bottom: 10px;

      .link-button {
        background-color: white;
        font-size: 20px;
        line-height: 1.5;
        width: 380px;
        color: #086fb9;
        border-color: #086fb9;
      }
    }
  }
}
