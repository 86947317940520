.generic-modal {
  .modal-header {
    background: $bg-blue-gradient;
    border-radius: 9px 9px 0 0;

    h3 {
      font-weight: bold;
      color: #ffffff;
      font-size: 40px;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
      width: 100%;

      small {
        color: #ffffff;
      }
    }
  }

  .modal-body {
    font-size: 18px;
    line-height: 1.8;
    padding: 15px 80px;

    label {
      font-size: 16px;
      line-height: 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: 25px;
    }
  }

  .modal-footer {
    @extend .justify-content-center;

    text-align: center;
  }
}

.acc-simple-modal {
  text-align: left;
}

.acc-modal-component .modal-title { // ".acc-modal-component": Need extra specificity to compete with .dashboard h5
  margin-top: 0;
  margin-bottom: 0;
}

.acc-modal-no-line {
  &.modal-header {
    border-bottom: none;
  }

  &.modal-footer {
    border-top: none;
  }
}
