.start-planning {
  margin: 19px 0;
}

.setup-exercises-cards {
  display: flex;
  justify-content: center;

  .acc-index-card {
    justify-content: flex-start;

    &.has-manage-exercise-card {
      width: auto;
    }
  }
}

.offsites-side-menu {
  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      height: 40px;
      color: $gray;

      &::before {
        content: '•';
        font-size: 55px;
        line-height: 20px;
        padding-right: 7px;
        z-index: 2;
        position: relative;
        color: $gray-300;
      }

      &.active {
        color: $primary;

        &::before {
          color: $primary;
        }
      }

      a {
        position: relative;
        top: -9px;

        &:hover {
          color: $primary !important;
        }
      }
    }
  }
}

.menu-line {
  width: 10px;
  height: 195px;
  border-left: 2px solid $border-color;
  position: absolute;
  top: 37px;
  left: 28px;
  z-index: 1;
}

.step-content-wrapper {
  border: 1px solid $border-color;
  border-radius: 7px;

  h3 {
    margin-bottom: 5px;
    color: $gray-900;
  }

  .container-fluid {
    padding-left: 0 !important;
  }
}

.wrapper-step-image {
  background-color: $gray-200;
  padding: 25px;
  text-align: center;
  border-radius: 7px 7px 0 0;
}

.placeholder-step-content {
  ul.nav-tabs {
    margin-bottom: 25px;

    .nav-link {
      border: 0;
      color: $gray;
      padding: 0.5rem 0.8rem;

      &:hover {
        color: $primary;
      }
    }

    .nav-link.active {
      background-color: $white;
      border-bottom: 1px solid $primary;
      color: $primary;
    }
  }
}

.step-content {
  display: none;
}

#setup-exercises {
  img.thumbnail {
    width: 100%;
    margin-bottom: 10px;
  }
}
