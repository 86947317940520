@import './responsive';

.acc-property-table {
  th {
    font-weight: bold;
    text-align: right;
    padding: 10px 20px 10px 0;
    white-space: nowrap;
  }

  td {
    padding: 10px 0;
  }

  th.top,
  td.top {
    vertical-align: top;
  }
}

.fixed-container {
  @extend .container;

  padding: 0 !important;

  @include small-screen {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    max-width: 100% !important;
  }
}
