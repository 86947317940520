input {
  &::placeholder {
    opacity: 1;
    color: $input-placeholder-color;
    font-weight: $font-weight-light;
  }
}

label {
  font-size: 14px;
  color: $gray;

  &.label-checkbox,
  &.label-radio,
  &.form-check-label {
    font-size: 16px !important;
    color: $gray-800 !important;
  }
}

.form-control--tall {
  height: 50px;
  line-height: 25px;
}

.acc-show-when-sibling-focused {
  display: none;

  .acc-focus-makes-sibling-show:focus ~ & {
    display: initial;
  }
}

.email-text-max-height {
  max-height: 300px;
  overflow-y: scroll;
}

.password-help-text {
  color: $orange;
}

$track-w: 40px;
$track-h: 8px;
$thumb-d: 16px;

@mixin range-track {
  box-sizing: border-box;
  border: none;
  width: $track-w;
  height: $track-h;
  background: #c4c4c4;
  border-radius: 20px;
}

@mixin range-thumb {
  margin-top: 0.5 * ($track-h - $thumb-d);
  box-sizing: border-box;
  border: none;
  width: $thumb-d;
  height: $thumb-d;
  border-radius: 50%;
  background: $primary;
}

.toggle-input {
  width: 40px;
  height: 1.5rem;
  outline: none;
  cursor: pointer;
  padding: 0;

  &,
  &::-webkit-slider-thumb {
    appearance: none;
  }

  &::-moz-range-thumb {
    appearance: none;
  }

  &::-webkit-slider-runnable-track {
    @include range-track;
  }

  &::-moz-range-track {
    @include range-track;
  }

  &::-ms-track {
    @include range-track;
  }

  &::-webkit-slider-thumb {
    @include range-thumb;
  }

  &::-moz-range-thumb {
    @include range-thumb;
  }

  &::-ms-thumb {
    @include range-thumb;

    margin-top: 0;
  }

  &::-ms-tooltip {
    display: none;
  }

  &.disabled {
    &::-webkit-slider-thumb {
      background: $gray;
    }

    &::-moz-range-thumb {
      background: $gray;
    }

    &::-ms-thumb {
      background: $gray;
    }
  }
}
