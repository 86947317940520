.page__header,
.page__actions {
  display: flex;
}

.page__actions {
  flex-direction: column;
}

.page__header {
  flex-direction: row;
  padding: 1.5rem 0;
  border-bottom: 1px solid $border-color;
  margin-bottom: 2rem;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.page__title {
  font-size: $h3-font-size;
  margin-bottom: 0;
  color: $navy-dark;
}

.page__action {
  margin-bottom: 0.5rem;
}

.page__action:last-child {
  margin-bottom: 0;
}

.page__breadcrumb {
  font-size: $h3-font-size;

  a {
    color: $navy-dark;
  }
}

@include media-breakpoint-up(md) {
  .page__header,
  .page__actions {
    flex-direction: row;
    align-items: center;
    text-align: left;
  }

  .page__title,
  .page__action {
    margin-bottom: 0;
  }

  .page__action {
    margin-right: 1.25rem;
  }

  .page__action:last-child {
    margin-right: 0;
  }
}
