h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1 {
  color: $h1-color;
  font-weight: $h1-font-weight;
  line-height: $h1-line-height;
}

h2 {
  color: $h2-color;
  font-weight: $h2-font-weight;
  line-height: $h2-line-height;
  text-transform: uppercase;
}

h3 {
  color: $h3-color;
  font-weight: $h3-font-weight;
  line-height: $h3-line-height;
}

h4 {
  color: $h4-color;
  font-weight: $h4-font-weight;
  line-height: $h4-line-height;
}

h5 {
  color: $h5-color;
  font-weight: $h5-font-weight;
  line-height: $h5-line-height;
}

h6 {
  color: $h6-color;
  font-weight: $h6-font-weight;
  line-height: $h6-line-height;
}

.font-size-md {
  font-size: 14px;
}

.text-gray {
  color: $gray;
}

.block-title {
  text-transform: uppercase;
  color: $gray-900;
  letter-spacing: 0.15em;
  text-align: center;
  font-size: 14px;
}

.line-height--sm {
  line-height: 1.3;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.alert {
  font-size: 14px;
}
