.acc-btn-caps {
  color: $white !important;
  margin: 6px;
  text-transform: uppercase;
  margin-bottom: 6px !important;
  border-radius: 5px;
}

.acc-btn {
  padding: 6px 16px;
}

.btn-md,
.btn-xs {
  font-size: 12px;
}

.btn-red {
  background: $red;
  border: 1px solid $white;
}

.btn-secondary,
.btn-outline-secondary {
  color: $gray;
  background: $white;
  border: 1px solid $gray-400;

  &:hover,
  &:active,
  &:focus {
    color: $gray;
    border: 1px solid $border-color;
    background: $gray-200;
  }
}

.badge-warning {
  color: white;
}

/*
* Ensures top nav dropdowns are truncated for long Org/Team names
* and that the caret icon in the dropdown isn't cut-off
*/

.truncated {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
  white-space: nowrap;
}

.dropdown-truncated-text {
  position: relative;
}

.dropdown-truncated-text::after {
  position: absolute;
  right: 0;
  top: 41%;
}

.vertical-right-edge-button {
  position: fixed;
  left: 100%;
  top: 50%;
  background-color: $blue-600;
  color: $white !important;
  width: fit-content;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateX(50%);
}

// Override default bootstrap's disabled styles for button
.btn:disabled {
  color: $gray-600;
  background: $gray-300;
  border-color: $gray-500;
}

.btn-link:disabled {
  border: 0;
  background: none;
}
