@import 'bootstrap_variables';

.sweet-alert {
  h2 {
    font-family: $font-family-default;
    font-size: 28px;
    line-height: 28px;
    color: $navy-dark;
  }

  p {
    font-family: $font-family-default;
    font-size: 16px;
    color: $gray-800;
    line-height: 135%;
  }

  button {
    font-family: $font-family-default;
    font-size: 16px;
    line-height: 16px;

    &.confirm {
      background-color: $brand !important;
    }
  }
}
