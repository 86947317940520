.widget {
  margin-bottom: 30px;
  background: $white;
  box-shadow: 0 1px 20px rgb(173 181 189 / 20%);
  border-radius: 5px;

  &--gray {
    background: $table-accent-bg;
    box-shadow: none;
  }
}

.widget__title {
  border-bottom: 1px solid $gray-300;
  padding: 20px 30px;
  font-weight: $font-weight-bold;
  color: $gray;
}

.widget__title__text {
  font-weight: $font-weight-bold;
  color: $gray;
  font-size: 1rem;
}

.widget__content,
.widget__footer {
  padding: 30px;
}

.widget__footer {
  border-top: 1px solid $gray-300;
}
