// App should not use margin, it creates scrollbars when used with full-height
#app {
  padding-top: 10em;

  @include media-breakpoint-up(sm) {
    padding-top: 8em;
  }

  @include media-breakpoint-up(md) {
    padding-top: 6em;
  }
}

#align-exercise {
  @include media-breakpoint-up(sm) {
    margin-top: 5.25em;
  }

  @include media-breakpoint-up(md) {
    margin-top: 3.25em;
    padding-bottom: 3rem;
  }
}

.align-background-gradient,
.align-background-gradient #main-wrapper {
  background: $bg-blue-gradient-new; // requires full-height
}

.exercise-no-pull-to-refresh {
  overscroll-behavior: contain;
}

.submit-back-btn {
  color: #878787;
}

.frame-box {
  #app {
    padding-top: 1.5rem;
  }

  nav {
    display: none;
  }
}

.align-container {
  @extend .container;
}

.align-container {
  margin-top: 5rem;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1rem;
    padding-right: 1em;
    width: 100%;
    max-width: 100%;
  }
}

#dropzone-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    height: 325px;
    margin-top: 1rem;
    left: -0.5rem;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;
  }
}

#dropzone {
  background: url('/images/plain_grid.png') center center no-repeat;
  background-size: 100% 100%;
  user-select: none;
}

.align-sidebar {
  background-color: #ffffff;
  border-radius: 3px;
  text-align: center;

  .align-360-header {
    padding: 2rem 1rem 0;
  }
}

.align-statements-inner {
  user-select: none;

  @include media-breakpoint-up(sm) {
    & > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.align-slide {
  width: 95px;
}

.align-mobile {
  @include media-breakpoint-up(sm) {
    display: none !important;
  }
}

.align-tablet {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.align-desktop {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.statements-action-btns {
  display: flex;
  justify-content: center;

  .norm-submit-btn {
    flex: 1;
  }
}

.norm-submit-btn {
  background-color: #5ecea4 !important;
  border: #5ecea4 !important;
}

.reset-grid-btn {
  background-color: #f6ca75 !important;
  border: #f6ca75 !important;
}

$dragggie-size: 5.3125rem;

.draggable-holder {
  width: $dragggie-size;
  height: $dragggie-size;
  margin: 5px;
}

.draggable-ghost {
  visibility: hidden;
}

.draggable,
.faux-draggable {
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 4px;
  z-index: 2;
  width: $dragggie-size;

  .statement-question {
    position: absolute;
    bottom: 0;
    right: 3px;
    font-size: 15px;
    color: grey !important;
  }

  .draggable-text {
    padding: 0;
    margin: 0;
    cursor: move;
    background-color: lighten($yellow, 8%);
    border: 1px solid darken($yellow, 5%);
    border-radius: 2px;
    opacity: 0.9;
    overflow: hidden;
    height: $dragggie-size;
    line-height: normal;

    div {
      height: $dragggie-size;
      width: $dragggie-size;
      padding: 2px;
      margin: 0;
      font-size: 13px;
      line-height: 1.2;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
    }

    .draggable-explanation {
      display: none;
    }

    span {
      width: inherit;
    }
  }

  &.draggable-expanding {
    .draggable-text {
      transition: transform 200ms ease-in-out;
    }

    .statement-question {
      visibility: hidden;
    }

    @include media-breakpoint-up(sm) {
      &:hover {
        .draggable-text {
          transform: scale(1.5);
          box-shadow: 0 5px 10px -5px rgb(0 0 0 / 60%);
          opacity: 1;
        }

        .draggable-explanation {
          display: block;
          font-size: 8px;
        }

        .draggable-title {
          font-size: 8px;
          text-transform: uppercase;
          font-weight: 600;
          display: block;
          margin-bottom: 3px;
        }
      }
    }
  }

  &:hover {
    z-index: 200;
  }
}

.draggable-holder {
  .draggable {
    display: block;
    margin: 0;
  }
}

#top-title {
  width: 100%;
  line-height: 0.5vw;
  text-align: center;
  position: absolute;
  top: 0;
  color: $white;
  padding: 0.3vw;
  white-space: nowrap;
}

#right-title {
  position: absolute;
  top: 48%;
  right: 0;
  color: $white;
  transform: rotate(270deg) translate(50%, -50%);
  transform-origin: right;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    margin-right: -0.5rem;
  }

  @include media-breakpoint-down(xs) {
    margin-right: 0;
  }
}

#left-title {
  position: absolute;
  top: 48%;
  left: 0;
  color: $white;
  transform: rotate(270deg) translate(-50%, 50%);
  transform-origin: left;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    margin-left: -0.5rem;
  }

  @include media-breakpoint-down(xs) {
    margin-left: 0;
  }
}

#bottom-title {
  position: absolute;
  line-height: 1.5vw;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: $white;
  white-space: nowrap;
}

.report_nav {
  background: $blue-light;
  box-shadow: 0 0 1rem rgb(0 0 0 / 10%);

  .nav-link {
    color: white;
  }

  .nav-link.active {
    background: rgb(255 255 255 / 25%);
  }
}

.report_header {
  > * {
    margin: 0;
  }

  strong {
    font-weight: 600;
  }

  background: $light;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-400;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 0.8rem 1.5rem;
}

.report_container {
  text-align: center;
  border-radius: 10px;
  margin: 0 auto;
  background: white;
  margin-bottom: 50px;
  max-width: 850px;

  &.shadow-box {
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0 1px 6px rgb(0 0 0 / 9%);
    border-radius: 3px;
    margin: 0;
    margin-bottom: 1rem;
  }

  h1,
  h2 {
    font-weight: bold;
    color: $primary;
    padding-top: 30px;
  }

  .subtitle {
    padding-bottom: 20px;
    color: grey;
    font-size: 20px;
  }

  .report_section {
    background: $blue-light;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.8;
    padding: 15px 50px;

    .acc-narrower &,
    &.acc-narrower {
      padding: 15px 20px;
    }

    h2 {
      color: white;
      text-align: left;
      line-height: 1.5;
      padding-left: 5px;
    }

    h2.summary {
      border-bottom: 1px solid #ffffff;
    }

    h4 {
      margin-bottom: 8px;
      margin-top: 16px;
      font-weight: bold;
      color: white;
      line-height: 1.5;
      font-size: 120%;
      text-transform: uppercase;
    }

    .my-answer-category {
      background: #ffffff;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &.need-addressing {
        span {
          color: #dc3c48;
        }

        .align-report-statement,
        .title-container {
          background-color: #fcc1c6;
        }
      }

      &.going-well {
        span {
          color: #1ac099;
        }

        .align-report-statement,
        .title-container {
          background-color: #a5ede0;
        }
      }

      &.different {
        span {
          color: #e67e22;
        }

        .align-report-statement,
        .title-container {
          background-color: #fbebae;
        }
      }

      &.most-important {
        span {
          color: $primary;
        }

        .align-report-statement,
        .title-container {
          background-color: #bdd4f4;
        }
      }

      &.least-important {
        span {
          color: #898989;
        }

        .align-report-statement,
        .title-container {
          background-color: #dad9da;
        }
      }

      .title-container {
        min-height: 120px;
        padding: 10px 5px;

        h4 {
          font-weight: bold;
          color: grey;
          text-transform: none;
        }
      }
    }

    .align-report-statement {
      margin: 0;
      padding: 10px;
      color: #222222;
      line-height: 1.4;
      font-size: 17px;
      border-top: 1px solid #cccccc;
      text-align: center;

      p {
        min-height: 70px;
      }
    }

    .team_selector {
      color: white;
      float: right;
      font-weight: 300;
      font-size: 14px;

      .fa {
        font-size: 18px;
      }
    }

    .congrats {
      color: white;
    }

    .legend {
      margin-bottom: 30px;
      color: white;
      float: right;
      border: 1px solid white;
      margin-top: 5px;
      border-radius: 3px;
      padding: 8px;
      padding-top: 3px;
    }
  }

  .acc-smaller-more-drawer {
    font-size: 1rem;
    line-height: 1.6;

    .acc-label-container {
      font-size: 12px;
    }
  }

  .modal .guidance {
    margin-top: 10px;
  }
}

.report-sidebar {
  margin-left: 2rem;
  margin-right: 2rem;

  @include media-breakpoint-up(md) {
    min-width: 285px;
    max-width: 285px;
    margin-right: 0;
  }
}

.report-sidebar-widget {
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 1px 6px rgb(0 0 0 / 9%);
  border-radius: 3px;
  margin-bottom: 1rem;

  .report-sidebar-widget-header {
    font-weight: normal;
    font-size: 1rem;
    color: $gray-800;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $gray-300;
    margin: 0;
  }

  .report-sidebar-widget-content {
    padding: 1rem 1.5rem;
    margin: auto;

    p {
      line-height: normal;
      font-size: 1rem;
      text-align: center;
      color: $gray;
    }

    .report-sidebar-icon-container {
      margin: 0 auto 1rem;
      text-align: center;

      i {
        font-size: 2rem;
        color: $cyan;
      }
    }

    .team-list-item {
      display: flex;
      align-items: center;
    }

    .team-list-item input {
      margin-right: 1rem;
    }

    .team-list-item span {
      font-size: 1rem;
      color: $gray-800;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .team-invite-buttons {
      margin: 1.5rem auto;
      text-align: center;
    }

    .btn.btn-primary {
      padding: 0.25rem 1.5rem;
      font-size: 1rem;
    }
  }

  .report-sidebar-widget-footer {
    padding: 2rem 1.5rem;
    border-top: 1px solid $gray-300;
    background-color: #f8f9fa;
    text-align: center;

    span {
      font-size: 1rem;
      color: $gray;
    }

    input {
      padding: 0.5rem 0 0.5rem 1rem;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 3px;
    }

    .acc-share-link-v2 {
      width: auto;
      margin: auto;

      button {
        width: 5rem;
        background: #ffffff;
        border: 1px solid #dee2e6;
        border-radius: 0 3px 3px 0;
        font-size: 1rem;
        color: $primary;
      }
    }
  }

  .compare-team-widget-name {
    display: block;
    font-size: 1rem;
    color: $gray;
    margin-bottom: 0.5rem;
  }

  .compare-team-widget-member {
    margin-bottom: 0.75rem;
    align-items: center;
  }

  .compare-team-widget-team {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .compare-team-widget-member-avatar {
    margin-right: 0.75rem;
  }

  .compare-team-widget-member-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .compare-team-widget-member-letters {
    color: #2f2f2f;
    font-weight: bold;
    margin-left: 1rem;
  }

  &.report-sidebar-widget--grey {
    @extend .panel--gray-light;

    box-shadow: none;
    padding: 1.75rem 0;

    .report-sidebar-icon-container,
    .report-sidebar-widget-content {
      margin: 0;
      padding: 0;
    }

    .report-sidebar-widget-header,
    .report-sidebar-widget-content,
    .report-sidebar-widget-footer {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .report-sidebar-icon-container,
    .report-sidebar-widget-footer {
      padding-bottom: 1rem;
    }

    .report-sidebar-widget-header {
      border-bottom: 0;
      font-weight: bold;
      color: #878787;
      padding-top: 0;
      padding-bottom: 0.85rem;
    }

    .btn-outline {
      background-color: transparent;
    }
  }
}

// When we want a small preview view of *non-functional* statement and drag targets
.compact-preview-container {
  max-width: 460px;
  margin: auto;
}

.compact-preview {
  $previewStatementWidth: 50px;

  background: $bg-blue-gradient;
  border-radius: 4px;
  display: flex;
  padding: 5px 2.5px;

  #statement-preview {
    height: 220px;
    width: 215px;
    text-align: center;
    display: inline-block;
  }

  .draggable {
    width: $previewStatementWidth;
    margin: 0;
  }

  .draggable-text {
    height: $previewStatementWidth;
    cursor: default;
    opacity: 1;

    & div {
      width: $previewStatementWidth;
      height: $previewStatementWidth;
      font-size: 8px;
    }
  }

  #dropzone-wrapper {
    width: 200px;
    display: inline-flex;
    margin-left: 13px;
    margin-top: 10px;
    cursor: default;
  }

  #dropzone {
    width: 160px;
    height: 160px;
  }

  #top-title {
    font-size: 10px;
  }

  #left-title {
    font-size: 10px;
    margin-left: -5px;
  }

  #right-title {
    font-size: 10px;
    margin-right: -5px;
  }

  #bottom-title {
    font-size: 10px;
    margin: 0;
  }
}

.collapse-guidance {
  font-size: 80%;
  background: $light;
  color: $gray-800;
}

.type-content-container {
  max-width: 620px;
  margin: auto;
  font-size: 16px;
}

.start-exercise-modal {
  width: 745px;
  margin-top: 40px;
  border-width: 0;
}

.start-exercise-modal-content {
  border: 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 9%);
}

.start-exercise-modal-header-text {
  font-size: 18px;
}

.start-exercise-modal-header-link {
  font-size: 16px;
}

.start-exercise-modal-close {
  position: relative;
  top: 1rem;
  right: 1rem;
}

.acc-align-navbar {
  color: $gray-800;
  background: white;
}

.acc-align-nav {
  .nav-link {
    color: $gray;

    &:hover {
      color: darken($gray, 15%);
    }
  }

  .active {
    color: $primary;
    border-bottom: 2px solid $primary;
  }
}

.acc-card-gradient {
  background: linear-gradient(139deg, #2d209e, #c56cd7);
}

.text-mixed {
  color: #f8c51b;
}

.text-positive {
  color: #06caab;
}

.text-negative {
  color: #e85513;
}

// --------------------------------------------
//  New mobile interface for align
// --------------------------------------------
@include media-breakpoint-down(xs) {
  .align-mobile-container {
    a.disabled {
      pointer-events: none;
      cursor: normal;
      color: lightgray;
    }

    .align-sidebar {
      position: relative;
      margin-top: -20px;
      background: #ffffff;
      height: 190px;

      h4 {
        padding: 4px;
        font-size: 1.2rem;
        margin: 0;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .align-statements-wrapper {
        display: flex;
        align-items: center;
        text-align: left;

        .align-slider-control {
          width: 30px;
          text-align: center;
        }
      }

      .align-statements {
        position: relative;
        flex: 1;
        height: 97px;
        background: $light;
        border: 1px solid $gray-400;
        overflow: hidden;
        user-select: none;

        .align-counter {
          position: absolute;
          top: 0;
          right: 4px;
          color: $primary;
          font-weight: 400;
          font-size: 12px;
          z-index: 3;
        }
      }

      .align-instructions {
        color: $gray-600;
        font-size: 0.9rem;
        margin: 5px;
      }

      .align-done {
        color: $gray-800;
        line-height: 1.2;

        small {
          color: $gray-600;
        }
      }

      .align-slide {
        position: relative;
        height: 95px;
        display: flex;
        align-items: center;
        justify-content: center;

        .align-slide-explanation {
          flex: 1;
          font-size: 13px;
          line-height: 1.2;
          padding: 5px;
        }
      }
    }

    #top-title {
      font-size: 3vw;
      top: 2vw;
      padding: 0.3vw;
    }

    #right-title {
      font-size: 3vw;
    }

    #left-title {
      font-size: 3vw;
    }

    #bottom-title {
      font-size: 3vw;
      bottom: 2vw;
    }

    .mobile-banner {
      background: $pastel-blue-2;
      padding: 1rem;
      margin-bottom: 2.5rem;
      display: flex;
      align-items: baseline;

      p {
        font-size: 0.75rem;
        line-height: 1.375rem;
        color: $gray-800;
      }
    }
  }
}

// --------------------------------------------
//    Align reports
// --------------------------------------------

.align-report-container {
  $side-padding: 30px;
  $side-padding-md: 50px;

  .align-report-top-navbar {
    min-height: 47px;
  }

  .align-report-row {
    border-bottom: 1px solid $nav-divider-color;

    &.align-report-nav {
      padding: 0;
      border-bottom: 0;
    }
  }

  .compare-widget {
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }
}

.align-report-navbar {
  background: #ffffff;
  border-radius: 3px;
  border-bottom: 1px solid $gray-400;

  @include media-breakpoint-down(md) {
    .container-fluid {
      padding: 0;
    }

    .navbar-brand {
      display: block;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
}

.align-report-step-nav {
  .align-report-step-nav-inner {
    background-color: $gray-200;
    padding: 0;

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid $nav-divider-color;
    }
  }

  .ordered-nav,
  .unordered-nav {
    padding: 12px 0 0;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    margin-bottom: 0;
  }

  .ordered-nav__item,
  .unordered-nav__item {
    padding: 20px;
    transition: 0.5s background-color;
    cursor: pointer;

    &:hover {
      background-color: $gray-300;
    }

    &.active {
      background-color: #ffffff;
      border: 1px solid $gray-300;
      border-bottom-width: 0;

      @include media-breakpoint-down(md) {
        border-left-width: 0;
        border-right-width: 0;
        border-bottom-width: 1px;

        &:first-child {
          border-top-width: 0;
        }

        &:last-child {
          border-bottom-width: 0;
        }
      }

      &.mobile-expand {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .ordered-nav__item,
    .unordered-nav__item {
      transition: all 0.5s ease-in-out;

      &.active {
        transition: none;
      }
    }

    .mobile-collapsed .ordered-nav__item,
    .unordered-nav__item {
      padding: 0 20px;
      max-height: 0;
      opacity: 0;
      overflow: hidden;

      &.mobile-expand {
        padding: 20px;
        max-height: 75px;
        opacity: 1;
        display: block;
        background-color: transparent;
        border-bottom-width: 0;

        &.active.mobile-expanded {
          padding: 0 20px;
          max-height: 0;
          opacity: 0;
        }
      }
    }

    .mobile-expanded .mobile-expand {
      display: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .align-report-step-nav.sticky {
    padding-bottom: 90px;

    .align-report-step-nav-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: $zindex-sticky;
      height: 81px;
    }
  }
}

.align-report-container,
.acc-report-navbar {
  background: white;
}

.frame-box {
  .align-report-header,
  .align-report-nav,
  .align-report-row {
    display: none;
  }
}

.align-report-summary {
  padding: 30px 0;
}

.acc-report-navbar {
  border-radius: 3px;
  min-height: 60px;
}

@media print {
  .start-page:not(:first-of-type) {
    page-break-before: always;
  }
}

.acc-share-btn {
  @extend .btn;
  @extend .btn-primary;

  font-size: 12px;
  padding: 2px 7px;
}

.align-report-nav {
  margin-bottom: 1rem;

  .acc-align-nav {
    position: relative;

    // a gray bottom line
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      bottom: 0;
      border-bottom: 1px solid $gray-400;
    }

    // show links in front of the bottom border
    .nav-link {
      z-index: 1;
    }
  }
}

.align-report-title-360 {
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.align-report-title {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.015rem;

  small {
    font-size: 1rem;
    color: $gray-800;
    letter-spacing: normal;
  }
}

.align-report-section {
  padding: 43px 50px;

  @include media-breakpoint-down(md) {
    padding: 43px 20px;
  }

  .small-scale {
    width: 180px;
  }

  border-radius: 5px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
}

.align-report-inner-section-center {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.answers-list {
  @include media-breakpoint-down(md) {
    padding-right: 0 !important;
    padding-left: 0;
  }

  .nav-tabs {
    padding-right: 0;
  }
}

.align-report-bucket {
  margin-bottom: 1rem;

  .align-report-bucket-label {
    margin-bottom: 0.35rem;
    font-weight: $font-weight-semi-bold;
    font-size: 1rem;

    i {
      line-height: 1.75rem;
    }
  }

  .align-report-bucket-orb {
    border-radius: 100%;
    width: 1rem;
    height: 1rem;
  }

  div.nav-item,
  li {
    border: 1px solid $gray;
    padding: 3px !important;
    border-radius: 4px;
    margin-bottom: 3px;
    color: $gray;
    cursor: pointer !important;

    &.no-border {
      border: 1px solid transparent;

      &:hover {
        border: 1px solid #dedede;
        background-color: #f7f7f8;
      }
    }

    &.active {
      border: 1px solid $primary;
      color: $primary;
    }

    &.align-statement-bucket-red {
      .fa-circle {
        color: red;
      }
    }
  }

  .small-scale {
    width: 180px;

    @include media-breakpoint-down(md) {
      min-width: 90px;
      max-width: 90px;
    }
  }
}

.align-statement-list,
.align-statement-list-legend {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.align-statement-list-legend {
  min-width: 11rem;

  span {
    color: $gray-800;
  }

  i {
    color: $gray-600;
  }
}

.align-statement-widget-sidebar-title {
  margin: 0 3rem;
}

.align-statement-select {
  display: none;
  margin-bottom: 1.5rem;
  border: 1px solid $gray-300;
  border-radius: 4px;
  padding: 0 8px;
  position: relative;

  @include media-breakpoint-down(sm) {
    display: block;
    clear: both;
  }

  select {
    width: 100%;
    height: 48px;
    font-weight: bold;
    font-size: 17px;
    color: $gray;
    background-color: #ffffff;
    border: none;
    user-select: none;
    appearance: none;
    outline: none;
    padding-right: 21px;
  }

  i {
    position: absolute;
    right: 10px;
    top: 12px;
    align-self: center;
    pointer-events: none;
  }
}

.align-improvement-guide,
.align-personal-chart-wrapper {
  .acc-share-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.align-improvement-guide {
  text-align: center;
  padding: 10px;

  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 10px 20px 10px 40px;
  }
}

.align-discussion-guidance {
  .align-discussion-section {
    border: 1px solid $gray-200;

    &:first-child {
      border-bottom: 0;
    }
  }

  .discussion-guidance-nav-wrapper {
    background-color: #ffffff;
  }

  .nav {
    padding: 6px 16px !important;

    .nav-item {
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .nav-link {
    border-radius: 15px;
    padding: 4px 16px;
  }
}

.team-discussion-help {
  background-color: #f7faff;
  border-radius: 5px;
  margin-right: 20px;
  padding: 18px;

  .help-text {
    color: $gray;
    padding: 0 70px;
  }
}

.align-report-in-progress {
  background: #dbf9ff;
  box-shadow: 0 4px 4px rgb(0 0 0 / 4%);
  font-size: 1rem;
  color: #38b8d2;
}

.header-container {
  width: 100%;
}

.landing-logos img {
  height: 40px;
  margin: 16px 24px;
}

.landing-container {
  padding: 0;
  padding-top: 138px;
  background: #ffffff;

  .top-teams {
    font-weight: $font-weight-semi-bold;
  }

  .info-container {
    background-color: #f7faff;
    padding: 10px 0 0;
  }

  .get-started-container {
    background-color: #f8f9fa;
    padding: 40px 0 80px;
  }

  .cards-container {
    padding: 10px 100px 40px;

    .card {
      line-height: 24px;
      font-weight: $font-weight-semi-bold;
      text-align: left;

      &.card-top-blue {
        border-top: 3px solid $primary;
      }

      &.card-top-purple {
        border-top: 3px solid $purple;
      }

      &.card-top-teal {
        border-top: 3px solid $teal;
      }

      &.card-top-orange {
        border-top: 3px solid $orange;
      }

      &.card-top-red {
        border-top: 3px solid $red;
      }
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.15em;
    font-weight: normal;
    color: #2f2f2f;
  }

  h2 {
    font-weight: bold;
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
    margin: 40px 0 25px;
    color: $gray-900;
  }

  .info-container {
    .menu-item {
      text-align: left;
      color: $gray;
      cursor: pointer;
      float: right;
      width: 300px;
      padding: 15px;

      &.active {
        color: $gray-900;
        background-color: #edf2fb;
      }

      .menu-title {
        font-size: 1rem;
        font-weight: $font-weight-semi-bold;
      }

      .menu-subtitle {
        font-size: 14px;
      }
    }

    .screenshot-container {
      background-color: #ffffff;
      box-shadow: -2px -2px 4px rgb(0 0 0 / 16%);
      border-radius: 5px;
      padding-bottom: 80px;

      img {
        max-width: 100%;
      }
    }
  }
}

.ordered-nav,
.unordered-nav {
  counter-reset: section;
  list-style-type: none;
  padding: 20px 0;

  .ordered-nav__item,
  .unordered-nav__item {
    display: block;

    @include media-breakpoint-up(lg) {
      display: inline-block;
    }

    @include media-breakpoint-down(md) {
      margin: 0;
    }

    color: $gray;

    a {
      color: $gray;
    }

    &.active {
      a {
        color: $primary;
      }
    }
  }

  .ordered-nav {
    &::before {
      display: inline-block;
      counter-increment: section;
      content: counter(section);
      width: 28px;
      height: 28px;
      line-height: 28px; // vertically center text
      font-size: 14px;
      margin-right: 14px;
      border-radius: 50%;
      background: $gray-600;
      text-align: center;
      font-weight: $font-weight-bold;
      color: $white;
    }

    &.active {
      &::before {
        background: $primary;
      }
    }
  }

  .ordered-nav__separator,
  .unordered-nav__separator {
    display: inline-block;
    color: $gray-600;
    margin: 0 30px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

/*
 * .align-mobile / .align-desktop don't behave in the most sane way
 * It does not appear that either will show when the browser width is
 * in the md range, this works around it rather than changes the other
 * functionality.
 */

.align-landing-mobile {
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.align-landing-desktop {
  display: block;

  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.align-landing-header {
  max-width: 1170px;
  text-align: center;
  margin: 30px auto;
  color: $gray;
  padding: 0 32px;
}

.align-landing-body {
  padding: 0 64px 64px;

  @include media-breakpoint-down(lg) {
    padding: 64px 32px;
  }

  .left-content {
    padding-top: 64px;

    @include media-breakpoint-down(lg) {
      padding-top: 0;
    }
  }

  .align-landing-hero-image {
    background-image: url('/images/landing-manager.png');
    max-width: 292px;
    width: 100%;
    height: 265px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.align-landing-hero-image-member {
      height: 321px;
    }
  }

  .align-landing-hero-image-container {
    @include media-breakpoint-down(md) {
      margin: auto;
      max-width: 16rem;
      padding-bottom: 2rem;
    }
  }

  h1 {
    line-height: 1.5em;
    margin-top: 20px;
  }

  .quote-body {
    margin-top: 40px;
    color: $gray;
    font-size: 17px;
  }

  .quote-sig {
    font-size: 14px;
    margin-top: 14px;
  }
}

.align-landing-progress-bar {
  max-width: 850px;
  margin: 2.5rem auto 2rem;
  padding: 0 2rem;
  box-sizing: content-box;
}

.align-landing-widget,
.align-landing-constrain,
.align-landing-logos {
  margin: auto;

  @extend .col-sm-12;
  @extend .col-md-8;
  @extend .col-lg-10;
  @extend .offset-md-2;
  @extend .offset-lg-1;
}

.widget.widget__content.align-landing-widget {
  padding: 2.5rem;
}

.align-landing-logos {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto;
  display: block;
}

.align-landing-widget {
  align-items: flex-end;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;

    .align-landing-hero-image {
      height: 12rem;
      margin: auto;
      margin-bottom: 2rem;

      &.align-landing-hero-image-member {
        height: 16rem;
      }
    }
  }

  h1 {
    font-size: 1.7rem;
    color: #113264;
    margin-top: 0.625rem;
  }

  h6 {
    font-weight: normal;
    font-size: 1.15rem;
    color: #113264;
    margin-bottom: 0;
  }

  .align-landing-widget-content {
    margin-bottom: 2.5rem;
  }
}

.align-report-navbar,
.align-report-navbar div {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.report-nav-right {
  button,
  a {
    @include media-breakpoint-down(md) {
      width: 100% !important;
      margin-top: 0.5rem;
    }
  }
}

.container-responsive-up-md {
  padding: 0;
  margin: auto;

  @include media-breakpoint-up(lg) {
    max-width: 1200px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 960px;

    &.container-responsive-up-md-padded {
      padding: 0 1rem;
    }
  }
}

.align-report-manage-team {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.habit-promotion-sidebar {
  max-width: 350px;
}

.habit-promotion-body {
  flex: 1;
}

.habit-promotion-suggestion-item {
  margin-left: 28px;
}

/*
 * Intro
 */

.align-intro .flex-1 {
  flex: 1;
}

.align-intro-inner-container {
  display: flex;
  flex: 1;
  justify-content: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.align-intro-left {
  margin-right: 6rem;
  max-width: 25rem;

  @include media-breakpoint-down(md) {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  h1 {
    max-width: 30rem;
    margin: auto;
    font-size: 35px;
  }

  img {
    max-width: 30rem;
    width: 100%;
    margin: auto;
  }
}

.align-intro-right {
  max-width: 25rem;
  padding-top: 6rem;
  display: flex;

  @include media-breakpoint-down(md) {
    margin-right: 1rem;
    margin-left: 1rem;
    padding-top: 2rem;
  }

  h4 {
    line-height: normal;
    font-size: 1.25rem;
  }
}

.align-intro-line-container {
  position: relative;

  a {
    text-decoration: none;
    color: #000000;

    &.btn {
      color: #ffffff;
    }
  }

  .align-intro-line-item {
    padding-bottom: 1rem;
    padding-left: 2rem;
    border-left: 1px solid $gray-400;
    max-width: 20rem;

    h6,
    p {
      margin-bottom: 0.5rem;
    }

    &:last-child {
      border-left-width: 0;

      &.full-line {
        border-left-width: 1px;
        padding-bottom: 0;
      }
    }

    .align-intro-line-item-dot {
      width: 11px;
      height: 11px;
      border-radius: 22px;
      border: 2px solid $gray;
      background-color: #ffffff;
      position: absolute;
      left: -5px;
    }

    .align-intro-line-item-content {
      position: relative;
      top: -3px;
    }

    &.active {
      padding-bottom: 3rem;

      h6 {
        color: #3789e4;
      }

      .align-intro-line-item-dot {
        background-color: #3789e4;
        border-color: #3789e4;
      }
    }

    &.inactive,
    p.faded {
      &,
      * {
        color: $gray-600;
      }
    }
  }

  &.light-pad {
    .align-intro-line-item {
      &,
      &.active {
        padding-bottom: 1rem;
      }
    }
  }
}

.align-intro-frame {
  height: 584px;
  outline: 0;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
}

/* Mobile Overrides */
@include media-breakpoint-down(sm) {
  .draggable {
    .draggable-text {
      div {
        line-height: 1.1;
      }
    }

    .draggable-title {
      font-size: 12px;
    }
  }
}

.align-report-commitments-sidebar {
  width: 309px;
  margin-right: 3.5rem;
}

.new-report-nav-next-button-outer-container {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }

  .new-report-nav-next-button-spacer {
    height: 3.5rem;
    width: auto;
  }

  .new-report-nav-next-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.5rem;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $zindex-sticky;
    cursor: pointer;
    transition: background-color 0.25s;

    &:hover {
      background-color: lighten($primary, 10%);
    }

    a {
      color: #ffffff;
      letter-spacing: 0.2px;

      i {
        display: inline-block;
        margin-left: 0.5rem;
        vertical-align: baseline;
        font-size: 14px;
      }
    }
  }
}

.step-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  .step-menu__item {
    position: relative;
    padding-bottom: 20px;

    &:not(:last-child)::before {
      content: '';
      display: block;
      position: absolute;
      border-left: 1px solid #3789e4;
      top: 50%;
      bottom: -2px;
      left: 9.5px;
    }
  }

  .step-menu__link {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      flex: none;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      border: 1px solid #0036c2;
      background: #ffffff;
      z-index: 1;
    }

    &--active {
      font-weight: bold;

      &::before {
        background: #0036c2;
      }
    }
  }
}

.align-emoji {
  .align-emoji__item {
    padding: 12px;
    border-radius: 5px;
    font-size: 0.875rem;
    text-align: center;
    cursor: pointer;

    &--active {
      box-shadow: 0 0 6px rgb(0 0 0 / 10%);
      font-weight: bold;
    }
  }
}

.align-print-scatterplots-outer-container {
  width: 100%;
  page-break-before: always;

  .align-print-scatterplots-container {
    // Add margins by default
    .align-print-scatterplot {
      page-break-inside: avoid;
      margin-bottom: 3rem;
    }

    // Reduce margins when we have a legend
    &.align-print-with-legend {
      .align-print-scatterplot {
        margin-bottom: 2rem;
      }
    }
  }
}

.align-print-scatterplot {
  text-align: center;
  overflow: hidden;
}

.align-print-report[mode='categories'] {
  .align-print-scatterplots-outer-container {
    width: 100%;
  }

  .align-print-scatterplots-container {
    margin-bottom: 0;

    .align-print-scatterplot {
      display: inline-block;
      text-align: center;
      overflow: hidden;
      margin-bottom: 0.5rem;

      &.col-2-5 {
        width: 20%;
        flex: 0 0 20%;
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
      }

      .align-print-scatterplot-title-container {
        height: 38px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        h6 {
          margin-bottom: 0;
        }
      }
    }
  }
}

.align-print-report[mode='summary'],
.align-print-report[mode='categories'],
.align-print-report[mode='perPage'] {
  .align-report-bucket-label-text,
  .align-print-report-comments {
    page-break-before: always;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.align-team-scatter-plot-container,
.align-team-scatter-plot-container.align-report-hoverable-statement-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2.25rem;
  margin-right: -2.25rem;

  .align-team-scatter-plot-item {
    text-align: center;
    flex: 1;
    flex-shrink: 0;
    min-width: 10rem;
    margin: 0 1rem;

    .align-team-scatter-plot-item-inner-container {
      width: 12rem;

      &.align-report-hoverable-statement {
        padding: 0 1rem !important;
      }

      @include media-breakpoint-down(sm) {
        margin: auto;
      }
    }

    .align-team-scatter-plot-item-title {
      padding-top: 0.5rem;
      display: flex;
      min-height: 3rem;
      justify-content: center;
      align-items: center;
      max-width: 10rem;
      margin: auto;
      font-size: 1rem;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      color: $gray-800;
    }
  }
}

.align-team-scatter-plot-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  .align-team-scatter-plot-item {
    text-align: center;
    flex: 1;
    flex-shrink: 0;
    min-width: 10rem;
    margin: 0 1rem;

    .align-team-scatter-plot-item-inner-container {
      width: 10rem;

      @include media-breakpoint-down(sm) {
        margin: auto;
      }
    }

    .align-team-scatter-plot-item-title {
      display: flex;
      min-height: 3rem;
      justify-content: center;
      align-items: center;
      max-width: 10rem;
      margin: auto;
      font-size: 1rem;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      color: $gray-800;
    }
  }

  .align-team-scatter-plot-item-spacer-item {
    flex: 1;
    flex-shrink: 0;
    min-width: 10rem;
    height: 1px;
    margin: 0 1rem;

    @include media-breakpoint-down(sm) {
      display: none;
      flex: 0;
    }
  }
}

/* Sidebar */

.align-report-sidebar__outer {
  padding: 2rem;
  padding-left: 0;
  background-color: #ffffff;
}

.align-report-sidebar {
  width: 19rem;
  position: sticky;
  top: 1.5rem;
  background-color: #ffffff;

  &.align-report-sidebar-dashboard {
    top: 6rem;
  }

  &,
  & .align-report-sidebar__section a {
    color: $gray;
  }

  .align-report-sidebar__section {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-300;

    &.collapse-toggle {
      display: none;
    }

    &[active],
    &[active] a,
    &.active,
    &.active a {
      color: $navy-dark;

      .align-report-sidebar__section__title span {
        font-weight: bold;
      }
    }
  }

  .align-report-sidebar__section__title {
    display: flex;
    align-items: center;

    i {
      font-size: 1.25rem;
    }

    span:not(.badge) {
      font-size: 1.125rem;
      margin-left: 1rem;
      display: inline-block;
    }
  }

  .align-report-sidebar__section__items {
    padding-left: 2.5rem;
    margin-top: 1.25rem;

    .align-report-sidebar__section__item {
      display: block;

      span:not(.badge) {
        font-size: 1rem;
        line-height: 2rem;
      }

      &[active] span,
      &.active span {
        font-weight: $font-weight-semi-bold;
      }
    }
  }

  .align-report-sidebar__aux-component {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

/* Sidebar Collapse */

/*
 * Someday it might be nice to enable animations. But they are too glitchy right now
.align-report-sidebar, .align-report-sidebar__outer {
  transition: width 0.25s ease-in-out;
}
*/

.align-report-sidebar__outer {
  @include media-breakpoint-down(md) {
    padding: 2rem 0 0;

    &,
    .align-report-sidebar {
      width: 100%;
    }
  }

  &.collapsable {
    .align-report-sidebar__section.active,
    .align-report-sidebar__section.collapse-toggle {
      display: block;
    }
  }

  &.collapsable.collapsed {
    width: 3rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 2rem 0;

    .align-report-sidebar {
      width: auto;
    }

    .align-report-sidebar__section__title {
      justify-content: center;
    }

    .align-report-sidebar__section__title span,
    .align-report-sidebar__section__items {
      display: none;
    }

    .align-report-sidebar__section.active,
    .align-report-sidebar__section.collapse-toggle {
      display: block;
    }
  }
}

.align-report-modern-wrapper {
  padding: 0 2rem;

  &.align-report-modern-wrapper-containerless {
    padding: 0;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0 1rem;
  }
}

.align-report-modern-container {
  margin-top: 2rem;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .align-report-section {
    @extend .widget;

    padding: 2rem 2.5rem;
    color: $gray-800;
    font-size: 1rem;

    @include media-breakpoint-down(md) {
      padding: 1rem;
    }

    h5 {
      color: $navy-dark;
    }

    h6 {
      color: $gray;
      font-size: 1rem;
    }

    hr {
      border-color: $gray-300;
    }

    .alert.alert-primary {
      display: flex;
      align-items: center;
      padding: 1rem;
      color: $primary;
      background-color: #f0f8ff;
      border: 0;
      font-size: 1rem;
      line-height: 1.5rem;

      i {
        display: inline-block;
        font-size: 1.25rem;
        margin-right: 0.75rem;
      }
    }

    .alert.alert-primary {
      display: flex;
      align-items: center;
      padding: 1rem;
      color: $primary;
      background-color: #f0f8ff;
      border: 0;
      font-size: 1rem;
      line-height: 1.5rem;

      i {
        display: inline-block;
        font-size: 1.25rem;
        margin-right: 0.75rem;
      }
    }
  }
}

.align-report-statements-benchmark-container {
  align-items: center;

  .align-report-statements-benchmark-heading {
    flex: 1;
  }
}

.align-report-statements-sort-control {
  align-items: center;

  span {
    width: 5rem;
  }
}

.align-report-statement-benchmark-compare-container {
  @extend .panel--gray-light;
  @extend .mt-4;
  @extend .p-3;
  @extend .d-flex;

  align-items: center;
}

.align-report-hoverable-statement-container {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.align-report-hoverable-statement {
  @extend .py-1;
  @extend .px-2;
  @extend .mb-1;

  border: 1px solid transparent;
  border-radius: 4px;
  border-width: 1rem 0;

  &,
  i.fa {
    transition: color 0.25s ease-in-out;
  }

  &:hover {
    border: 1px solid #dedede;
    background-color: #f7f7f8;
    border-color: #f7f7f8;
    border-width: 1rem 0;

    &,
    i.fa {
      color: $primary;
    }
  }

  i {
    margin-top: 0.15rem;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
  }

  .statement-text {
    top: 1px;
    position: relative;
  }
}

.align-report-md-aux-widget {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.align-report__quick-add-habits-widget {
  .title {
    display: flex;
    align-items: center;
    color: $navy-dark;
    font-weight: $font-weight-semi-bold;

    i {
      font-size: 1.25rem;
    }

    span {
      font-size: 1.125rem;
      margin-left: 1rem;
      display: inline-block;
    }
  }

  p {
    font-size: 1rem;
    color: $gray-800;
  }

  ul.nav {
    padding-right: 0;
  }

  .selectable-list {
    @extend .m-0;
  }

  .selectable-list-item {
    padding: 0.5rem;
    border: 1px solid $gray-300;
    box-sizing: border-box;
    border-radius: 3px;
    align-items: center;

    i {
      margin-top: 1px; // The font awesome icon is just a tiny bit misaligned
      display: inline-block;
    }
  }
}

h6.checkin-builder-section-title {
  font-size: 0.875rem;
  font-weight: $font-weight-semi-bold;
  color: $gray;
}

.manager-landing-wrapper {
  max-width: 794px;

  p {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  a {
    font-weight: $font-weight-semi-bold;
  }

  .manager-landing-heading {
    margin-bottom: 2.3rem;
    text-align: center;
  }

  .org-logo {
    max-width: 8rem;
    max-height: 8rem;
  }

  .card-title {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 0.08rem;
    font-weight: $font-weight-semi-bold;
    color: $gray;
    margin-bottom: 1.25rem;
  }
}

.navbar-landing {
  box-shadow: 0 0 4px rgb(0 0 0 / 7%);
}

.align-green {
  color: $teal;
}

.align-red {
  color: $red;
}

.align-yellow {
  color: $amber;
}

#member-create-modal {
  margin: 4.375rem auto;
  max-width: 29rem;

  .member-modal-header {
    text-align: center;

    .modal-title {
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: $gray-800;
      margin-bottom: 0.5rem;
      font-weight: normal;
    }

    p {
      font-size: 0.875rem;
      line-height: 145%;
      text-align: center;
      color: $gray-800;
      margin-bottom: 0.5rem;
    }
  }

  .member-modal-body {
    max-width: 21.25rem;
    margin: 0 auto;

    .form-group {
      margin-bottom: 1.5rem;

      label {
        margin-bottom: 4px;
      }
    }

    .form-group label,
    .form-label {
      font-size: 0.875rem;
      color: $gray;
    }

    .not-you {
      margin-top: 4rem;
      text-align: center;

      p {
        font-size: 0.875rem !important;
      }
    }

    .text-field {
      background: $white;
      border: 1px solid $gray-600;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 1rem;
      font-size: 1rem;
      height: 3.25rem;
      color: $gray-800;
    }
  }
}

.align-statement-card {
  display: flex;
  align-items: center;
  border: 1px solid $gray-300;
  border-radius: 3px;
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.375rem;
  line-height: 1.2;
}

.blue-orb {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.65rem; // vertically center text
  font-size: 0.875rem;
  margin-right: 0.875rem;
  border-radius: 50%;
  text-align: center;
  font-weight: $font-weight-bold;
  color: $white;
  background: $primary;
}

.my360__quick-add-habits-widget {
  width: 15rem;

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.my360__quick-new-checkin-form {
  width: 30rem;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.align-report-post-align-pulse-highlight-box {
  background-color: #d7e8f7;

  .btn.btn-secondary {
    color: #3d59fa;
  }
}

.align-report-post-align-pulse-question {
  font-size: 1.125rem;
}

.align__next-steps__personal-report-opt-in {
  margin-top: 34px;

  .form-group {
    margin: 0 0 0.2rem !important;
  }

  p {
    margin-left: 1.4375rem;
    font-size: 0.875rem !important;
    line-height: 1.1875rem !important;
    color: $gray-800 !important;
  }

  label {
    margin-left: 4px;
    font-size: 1rem !important;
    line-height: 135%;
    color: $gray-800 !important;
    font-weight: normal !important;
  }
}

.align-report-old {
  max-width: 75rem;
  margin: 0 auto;

  .align-report-bottom-padding {
    margin-bottom: 5rem;
  }

  &.align-report-old-containerless {
    max-width: 100%;

    .align-report-old-outer-gray-container {
      background-color: $gray-100;

      h2 {
        color: $black;
      }
    }

    .align-report-old-inner-container {
      margin: 0 auto;
      max-width: 75%;
    }
  }

  .speak-with-experts {
    margin-bottom: 5rem;
  }
}

// Can remove this if teamHabit.blade is deleted
.teams-page-section-header {
  font-weight: $font-weight-light-semi-bold;
  font-size: $font-size-base;
  line-height: 1.75rem;
  color: $black;
}

.perspective-widget {
  .teams-page-section-header {
    &.pushed-down {
      margin-top: 23rem;
    }
  }
}
