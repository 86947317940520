@import 'resources/assets/bootstrap/scss/mixins/_buttons';

/*
 * General Styles
 */

.perspective-nav-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.perspective-nav-column-container {
  width: 160px;

  @include media-breakpoint-down(sm) {
    width: 48px;
  }
}

.perspective-next-button-container {
  margin-top: 2rem;
  text-align: right;
}

.perspective-next-button {
  white-space: nowrap;
  transition: 0.5s opacity;

  &[disabled] {
    opacity: 0.75;
  }
}

/*
 * Scale
 */

.acc-error-color {
  color: $danger;
}

.acc-scale-question {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;

  @include media-breakpoint-down(xs) {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.acc-scale-question p {
  margin-bottom: 0.3rem;
  font-size: $font-size-lg;
  color: $dark;
  text-align: center;
}

.acc-scale-question.modern p {
  margin-bottom: 1rem;
  padding: 0 8rem;

  @include media-breakpoint-down(sm) {
    padding: 0 1rem;
  }
}

.acc-scale-question.acc-invalid {
  border: solid $danger 5px;
  background: #ffeeee;
}

.acc-scale-question {
  .acc-scale-question-scale {
    display: flex;
    flex-direction: row;
  }

  .acc-scale-question-scale-button {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &:hover {
      .acc-scale-question-scale-orb:not([selected], [data-selected]) {
        border-width: 5.25px;
      }
    }
  }

  .acc-scale-question-scale-label-spacer {
    flex: 1;
  }

  .acc-scale-question-scale-label {
    flex: 1;
    font-weight: bold;
    line-height: normal;
    font-size: 14px;
    align-self: center;
    width: 100px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      flex: 0;
      margin: 16px 8px 0;
    }
  }

  .acc-scale-question-scale-label.disagree {
    text-align: right;
    color: #fa4344;
  }

  .acc-scale-question-scale-label.agree {
    text-align: left;
    color: #00a079;
  }

  .acc-scale-question-scale-label-container.mobile {
    display: none;

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }

  .acc-scale-question-scale-label.desktop {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .acc-scale-question-scale-orb {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: 3px solid #d2e1ee;
    border-radius: 100%;
    transition: border-width 0.25s;

    &[selected],
    &[data-selected] {
      border-width: 8px;
    }

    &[rating='-1'],
    &[rating='1'],
    &[data-rating='-1'],
    &[data-rating='1'] {
      height: 22px;
      width: 22px;

      &[selected],
      &[data-selected] {
        border-width: 11px;
      }
    }

    &[rating='-2'],
    &[rating='2'],
    &[data-rating='-2'],
    &[data-rating='2'] {
      height: 30px;
      width: 30px;

      &[selected],
      &[data-selected] {
        border-width: 15px;
      }
    }

    &[rating='-3'],
    &[rating='3'],
    &[binary],
    &[data-rating='-3'],
    &[data-rating='3'] {
      height: 36px;
      width: 36px;

      &[selected],
      &[data-selected] {
        border-width: 18px;
      }
    }

    &[rating='-3'],
    &[binary][rating='0'],
    &[data-rating='-3'],
    &[binary][data-rating='0'] {
      border-color: #fa4344;
    }

    &[rating='-2'],
    &[data-rating='-2'] {
      border-color: #f68d59;
    }

    &[rating='-1'],
    &[data-rating='-1'] {
      border-color: #ffc35c;
    }

    &[rating='1'],
    &[data-rating='1'] {
      border-color: #8de1a9;
    }

    &[rating='2'],
    &[data-rating='2'] {
      border-color: #75decc;
    }

    &[rating='3'],
    &[binary][rating='1'],
    &[data-rating='3'],
    &[binary][data-rating='1'] {
      border-color: #00a079;
    }
  }
}

.acc-scale-question.compact {
  padding: 15px 0;

  p {
    font-size: $font-size-base;
    padding: 0;
  }

  .acc-scale-question-scale-orb {
    transform: scale(0.8);
  }
}

/*
 *  Progress Bar
 */

.orb-progress-bar {
  width: 100%;
  min-height: 54px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  .orb-progress-bar-inner {
    flex: 1;
    max-width: 310px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    &.orb-progress-bar-step-inner {
      max-width: 100%;
    }
  }

  .orb-progress-bar-section {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &[last],
    &.last {
      max-width: 13px;
    }

    .orb-progress-bar-orb {
      width: 13px;
      height: 13px;
      background: #ffffff;
      border: 2px solid #3789e4;
      box-sizing: border-box;
      border-radius: 26px;
      transition: 0.25s border-width;

      &[current],
      &[past],
      &.current,
      &.past {
        border-width: 6.5px;
      }
    }

    .orb-progress-bar-connector {
      flex: 1;
      height: 3px;
      background: #dee2e6;

      .orb-progress-bar-connector-inner {
        background-color: #3789e4;
        height: 3px;
        width: 0;
        transition: 0.25s width;

        &[past],
        &.past {
          width: 100%;
        }
      }
    }
  }

  .orb-progress-bar-step {
    text-align: center;
    margin-bottom: 1rem;
    color: $gray;
    height: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @include media-breakpoint-down(md) {
      height: 48px;
    }

    @include media-breakpoint-down(sm) {
      height: 64px;
    }
  }

  .orb-progress-step-container {
    flex: 1;

    .orb-progress-bar-section {
      position: relative;
      left: 50%;
      margin-left: -13px;

      .orb-progress-bar-orb {
        width: 23px;
        height: 23px;
        border-radius: 23px;
        background: #dee2e6;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &[current],
        &.current,
        &[past],
        &.past {
          background-color: #3789e4;
        }

        i {
          color: #ffffff;
          font-size: 11px;
        }
      }

      &[last],
      &.last {
        max-width: 23px;
      }
    }
  }
}

.acc-done-button {
  min-width: 180px;
  text-transform: uppercase;

  &:not(.btn-lg) {
    font-size: 20px;
  }
}

.acc-modal-dimension-letter {
  font-weight: bold;
  padding: 10px 0;
}

.acc-modal-top-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.acc-perspective { // This extra selector needed or else ".dashboard h3" takes precedence
  .acc-title {
    margin-top: 0;
    margin-bottom: 10px;
    color: black;
  }

  .acc-subtitle {
    margin-top: 0;
    margin-bottom: 20px;
    color: $gray-800;
  }
}

.acc-tab {
  text-transform: uppercase;
}

// repeat class name to overpower other selector
.acc-tab.active.acc-tab.active {
  color: $navy;
  background: $gray-200;
}

.acc-card {
  @extend .acc-message-box;

  hr {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.acc-card-compressed {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;

  .acc-scaleBar .acc-bar-container {
    margin-top: 0;
  }
}

.acc-card-from-markup {
  text-align: left;

  h6 {
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 0;
    font-size: 16px;
  }

  h3 {
    margin-top: 0;
  }
}

.acc-card-border {
  border: 2px solid $primary;
}

.acc-perspective-type-cards {
  padding: 20px;

  .acc-card-watermark {
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 47px;
    color: $primary-lighter;
    margin: -30px -10px;
  }

  p {
    text-align: left;
    font-size: 14px;
    text-indent: 25px;
  }
}

.acc-pair-report .acc-card .acc-title {
  font-size: 28px;
}

.acc-pair-name {
  color: $success;
  font-weight: bold;
}

.acc-lenses .acc-card .acc-title {
  font-size: 28px;
}

.acc-card-button {
  min-width: 200px;
  text-transform: capitalize;
  font-size: 18px;

  @include button-variant(white, $primary);

  // Sigh. !important is contagious. Here, we're fighting the "$white !important;" that's applied to all .btn acc-btn-caps in app.scss:
  color: $primary !important;
}

.acc-card-bookmark {
  position: absolute;
  right: 30px;
  top: 0;
  width: 80px;
}

.acc-card-bookmark-text {
  text-anchor: middle;
  fill: white;
}

.acc-card-biggest-differences {
  background: #e1e2f2;
}

.acc-card-strong-difference {
  background-color: lighten($danger, 30%);
  position: relative; // for absolute-positioned triangle "bookmark" tag
  .acc-card-bookmark-triangle {
    fill: lighten($danger, 15%);
  }
}

.acc-card-mild-difference {
  background-color: lighten($warning, 30%);
  position: relative; // for absolute-positioned triangle "bookmark" tag
  .acc-card-bookmark-triangle {
    fill: lighten($warning, 15%);
  }
}

.acc-card-all-good {
  background-color: lighten($success, 30%);
  position: relative; // for absolute-positioned triangle "bookmark" tag
  .acc-card-bookmark-triangle {
    fill: lighten($success, 15%);
  }
}

.acc-card-columns {
  margin-top: 2em;
  display: flex;

  div {
    flex: 1 0 0;
    margin-right: 1em;
  }

  ul {
    padding-left: 1.5em;
  }
}

.acc-resonates-no {
  transition: all 100ms ease-in-out;

  &.acc-card {
    opacity: 0.6;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.acc-card-border {
    border-color: $gray-800;
  }

  p { // Extra selector level needed to override ".acc-perspective-type-cards p"
    font-size: 1em;
    margin-bottom: 3px;
  }

  .acc-card-watermark {
    font-size: 35px;
    margin-top: -22px;
  }

  .acc-btn-resonate {
    font-size: 11px;
  }

  .acc-disagree:not(:hover, :active) {
    background: lighten($danger, 50%);
  }
}

.acc-resonates-yes {
  transition: all 200ms ease-in-out;
}

.acc-carousel-img-xs {
  margin-left: auto;
  margin-right: auto;
}

.acc-carousel-img-sm {
  padding-left: 50px;
  padding-right: 50px;
}

@include media-breakpoint-only(sm) {
  .acc-modal-larger-sm {
    min-width: 700px; // Instead of 600px
  }
}

.acc-carousel-turn-icon {
  font-size: 50px;
  color: $gray-200;
  opacity: 0.3;

  .left & {
    left: 30px !important;
  }

  .right & {
    right: 30px !important;
  }
}

.acc-carousel-control {
  opacity: 1;
  background: none !important; // important to override bootstrap's selectors that have more specificity
  width: 33% !important;

  &:hover {
    .acc-carousel-turn-icon {
      opacity: 1;
    }
  }

  &:active {
    .acc-carousel-turn-icon {
      color: darken($gray-200, 30%);
    }
  }
}

.acc-indicators-primary {
  li {
    border-color: $primary;
    background: #dddddd;

    &.active {
      background: lighten($primary, 20%);
    }
  }
}

.perspective-intro-slide {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  border-radius: 9px 9px 0 0;
}

#perspective-intro-slide1 {
  background-image: url('/images/aboutPerspective/slide_1.png');
}

#perspective-intro-slide2 {
  background-image: url('/images/aboutPerspective/slide_2.png');
}

#perspective-intro-slide3 {
  background-image: url('/images/aboutPerspective/slide_3.png');
}

@media (max-width: 500px) {
  #perspective-intro-slide1 {
    background-image: url('/images/aboutPerspective/slide_1_mobile.png');
  }

  #perspective-intro-slide2 {
    background-image: url('/images/aboutPerspective/slide_2_mobile.png');
  }

  #perspective-intro-slide3 {
    background-image: url('/images/aboutPerspective/slide_3_mobile.png');
  }
}

$gridborder: 1px solid $primary-lighter;

.acc-gridtable {
  font-size: 16px;

  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

.acc-gridtable_header-row {
  font-weight: bold;
}

.acc-gridtable_row {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }
}

.acc-gridtable_cell {
  padding: 0.5rem;
  white-space: nowrap;
  height: 100%;
}

.acc-gridtable_colored-cell {
  // font-weight: bold; // Hard to read due to bad anti-aliasing, on chrome at least
  color: white;
}

.acc-gridborder_row {
  border-left: $gridborder;
  border-bottom: $gridborder;
  height: 100%;

  a.acc-row-link:hover:not(:active) & {
    opacity: 0.8;
  }

  @include media-breakpoint-down(sm) {
    border-top: $gridborder;
  }
}

.acc-gridborder_row-first {
  border-top: $gridborder;
}

.acc-gridborder_cell {
  border-right: $gridborder;
}

.acc-perspective-dim-header_cell {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.5;
}

.acc-perspective-dim-header_sub {
  font-size: 12px;
}

.perspective-survey-choices {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  padding: 1rem 0;

  .big-option-button {
    justify-content: flex-start;
    margin: 0.5rem 0.25rem;
    width: 171px;
    height: 145px;

    &:not(.active) {
      color: #828282;
    }
  }
}

.perspective-team-invite {
  padding: 0 40px 80px;

  .perspective-next-button-container {
    text-align: right;
  }

  .perspective-contents {
    margin: 0;
  }
}

.perspective-questions,
.perspective-survey-choices {
  max-width: 750px;
  margin: 0 auto;
}
