.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid $gray-300;
  margin: 0 0 30px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border-bottom: 3px solid transparent;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 0 0 8px;
  margin-right: 25px;
  color: $gray;
  cursor: pointer;
}

.react-tabs__tab--selected {
  border-bottom: 3px solid $primary;
  color: $primary;
  font-weight: $font-weight-semi-bold;
}

.react-tabs__tab--disabled {
  color: $gray-300;
  cursor: default;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
