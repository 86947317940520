@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes flash-danger {
  0% { background: rgba($danger, 0); }
  50% { background: rgba($danger, 0.4); }
  100% { background: rgba($danger, 0); }
}

@keyframes disappear {
  from { transform: scale3d(0); }
  to { transform: scale3d(0.5, 0.5, 0.5); }
}

@keyframes pulse {
  0% {
    background-color: rgb(235 235 235);
  }

  50% {
    background-color: rgb(245 245 245);
  }

  100% {
    background-color: rgb(235 235 235);
  }
}

@keyframes fake-loader-pulse {
  0% { opacity: 0.25; }
  25% { opacity: 0.3; }
  40% { opacity: 0.6; }
  50% { opacity: 0.7; }
  60% { opacity: 0.6; }
  75% { opacity: 0.3; }
  100% { opacity: 0.2; }
}
