@media print {
  html,
  body {
    background: white;
    font-size: 12pt;
    height: auto;
  }
}

// Caveat:
//  For some reason, Safari’s File -> Export as PDF doesn't honor page styles
@page {
  margin: 2.5%;
}
