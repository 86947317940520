// A stop-gap measure while transitioning to Bootstrap 4

.acc-topbar {
  padding: 0;
}

.sidebar {
  a { font-weight: bold; }
  hr { border-top: 1px solid #eeeeee; }
}
